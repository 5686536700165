import React from "react";
import PropTypes from "prop-types"
import Cage from "../../../components/devices-rows/cage/Cage";
import ButtonGroup from "../../../components/basics/button/button-group/ButtonGroup";
import Button from "../../../components/basics/button/Button";
import Menu from "../../../components/basics/menu/Menu";
import MenuItem from "../../../components/basics/menu/MenuItem";
import {reset, setSeparationClearErrors, setWorkType} from "../../../IOT/device-functions/SeparationCageFunctions";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {show} from "redux-modal";
import {ModalName as ConfirmModalName} from "../../../components/modals-new/confirm-modal/ConfirmModal";
import CageSetWorkTypeModal, {ModalName as CageSetWorkTypeModalName} from "../../../components/modals-new/cage-set-work-type/CageSetWorkTypeModal";
import CreateInsertionModal, {ModalName as CreateInsertionModalName} from "../../../components/modals-new/create-insertion/CreateInsertionModal";
import InfoBox from "../../../components/basics/info-box/InfoBox";
import CageExpandDataModal from "../../../components/modals-new/cage-expand-data/CageExpandDataModal";
import CageMarkerModal, {ModalName as CageMarkerModalName} from "../../../components/modals-new/cage-marker/CageMarkerModal";
import _ from "lodash";
import CompareInsertionsModal from "../../../components/modals-new/compare-insertions/CompareInsertionsModal";
import CompareDayDataModal from "../../../components/modals-new/compare-day-data/CompareDayDataModal";
import ReactGA from "react-ga";
import {getInterfName, groupDevicesByGatewayID} from "../../../utils/DevicesUtils";
import {DevType} from "../../../constans/devices";
import NewIOT from "../../../IOT/NewIOT";
import sinon from "sinon";
import {fakeCreateAndSendMessageObject, fakeStartSendingDeviceState, refresh} from "../../../demo/cage/fakeFunctions";
import {getShowAliases, isUsingFakeData} from "../../../utils/SettingsUtils";
import withRoles from "../../../components/withRoles";
import {Roles} from "../../../constans/roles";
import {getLocationID, getLocationName} from "../../../utils/BuildingUtils";
import NoDataFoundLoading from "../../../components/no-data-found-farm-view/NoDataFoundLoading";
import {withTranslation} from "react-i18next";
import {updateDevices} from "../../../api/devices/updateDevices";
import moment from "moment";
import {
    createEndInsertionNotificationFailure,
    createEndInsertionNotificationSuccess,
    endSettlementForDevice
} from "../../../api/feeding/Settlement";
import {getNewestSettlements} from "../../../selectors/settlementSelector";

export class SeparationCageTab extends React.Component {

    state = {
        selectedCages: [],
        event: undefined,
        showAlias: getShowAliases()
    };
    messageIDs = [];

    constructor(props) {
        super(props);
        const useFakeData = isUsingFakeData();
        if (useFakeData) {
            sinon.restore();
            sinon.stub(NewIOT, "startSendingDeviceState").callsFake(fakeStartSendingDeviceState);
            sinon.stub(NewIOT, "createAndSendMessageObject").callsFake(fakeCreateAndSendMessageObject);
            sinon.stub(NewIOT, "sendRequestForAggregatedData").callsFake(() => {
            });
            this.timer = setInterval(() => {
                refresh(this.props.cages)
            }, 5 * 1000)
        }
        this.sendData();
        ReactGA.modalview("farm/cage");
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.cages.length !== prevProps.cages.length) {
            this.sendData();
        }
    }

    sendData() {
        NewIOT.removeFromInterval(this.messageIDs);
        let devices = groupDevicesByGatewayID(this.props.cages);
        if (devices) {
            for (let devs of devices.values()) {
                if (devs[DevType.CAGE].length) {
                    this.messageIDs.push(NewIOT.startSendingDeviceState(devs[DevType.CAGE], undefined, undefined, {keepAlive: true}));
                    NewIOT.sendRequestForAggregatedData(devs[DevType.CAGE]);
                }
            }
        }
    }

    onDeviceChange = (props = this.props) => {
        const {cages} = props;
        let selectedCages = this.state.selectedCages.slice(0);
        selectedCages.forEach(cage => {
            let c = cages.find(c => c.DevID === cage.DevID);
            if (!c) {
                selectedCages = selectedCages.filter(cg => cg.DevID !== cage.DevID);
            } else if (!_.isEqual(cage, c)) {
                selectedCages[selectedCages.findIndex(o => o.DevID === cage.DevID)] = c;
            }
        });
        this.setState({
            selectedCages: selectedCages
        })
    };

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if (!_.isEqual(this.props.cages, nextProps.cages)) {
            this.onDeviceChange(nextProps);
        }
    }

    onCageSelected = cage => {
        let selectedCages = this.state.selectedCages.slice(0);
        if (selectedCages.filter(item => item.DevID === cage.DevID).length > 0) {
            selectedCages = selectedCages.filter(item => item.DevID !== cage.DevID);
        } else {
            selectedCages.push(cage);
        }
        this.setState({
            selectedCages
        })
    };

    onButtonClick = e => {
        this.setState({
            event: e
        })
    };

    onResetConfirmed = props => {
        for (let cage of this.state.selectedCages) {
            reset(cage);
        }
        props.handleHide();
    };

    componentWillUnmount() {
        sinon.restore();
        clearInterval(this.timer);
    }

    onResetClick = () => {
        let children = (
            <ul>
                {
                    this.state.selectedCages.map((cage, index) => <li key={index}>{cage.Name}</li>)
                }
            </ul>
        );
        this.props.show(ConfirmModalName, {
            text: this.props.t("farmView.cage.resetText"),
            title: this.props.t("farmView.cage.resetTitle"),
            confirmText: this.props.t("yes"),
            onConfirmed: this.onResetConfirmed,
            children
        })
    };

    onStopConfirmed = props => {
        for (let cage of this.state.selectedCages) {
            setWorkType(cage, 0);
        }
        props.handleHide();
    };

    onClearErrors=(props)=>{
        for (let cage of this.state.selectedCages) {
            setSeparationClearErrors(cage);
        }
        props.handleHide();
    };

    onClearErrorsClick=()=>{
        let children = (
            <ul>
                {
                    this.state.selectedCages.map((cage, index) => <li key={index}>{cage.Name}</li>)
                }
            </ul>
        );
        this.props.show(ConfirmModalName, {
            text: this.props.t("farmView.cage.clearErrorsText"),
            title: this.props.t("farmView.cage.clearErrorsTitle"),
            confirmText: this.props.t("yes"),
            onConfirmed: this.onClearErrors,
            children
        })
    };

    onStopClick = () => {
        let children = (
            <ul>
                {
                    this.state.selectedCages.map((cage, index) => <li key={index}>{cage.Name}</li>)
                }
            </ul>
        );
        this.props.show(ConfirmModalName, {
            text: this.props.t("farmView.cage.stopText"),
            title: this.props.t("farmView.cage.stopTitle"),
            confirmText: this.props.t("yes"),
            onConfirmed: this.onStopConfirmed,
            children
        })
    };

    onSetWorktypeClick = () => {
        this.props.show(CageSetWorkTypeModalName, {});
    };

    onAddNewInsertion = () => {
        this.props.show(CreateInsertionModalName, {});
    };

    onEndCurrentInsertion = () => {
        const {t} = this.props;
        this.props.show(ConfirmModalName, {
            text: t("modals.chamberTransferModal.endInsertionText"),
            title: t("modals.chamberTransferModal.titleEndInsertion"),
            confirmText: t("yes"),
            onConfirmed: props => {
                let promises = [];
                for (let cage of this.state.selectedCages) {
                    promises.push(endSettlementForDevice({DevID: cage.DevID}));
                }
                Promise.all(promises).then(res => {
                    createEndInsertionNotificationSuccess(res);
                }).catch(e => {
                    createEndInsertionNotificationFailure(e);
                })
                props.handleHide();
            }
        })
    };

    onCageMarkerClick = () => {
        this.props.show(CageMarkerModalName, {});
    }

    clearSelection = () => {
        console.log("clear");
        this.setState({
            selectedCages: []
        })
    }

    onRightClick = (e, cage) => {
        this.setState({
            event: e,
            selectedCages: [cage]
        })
    };

    checkIfDisabled() {
        const {shadows} = this.props;
        const {selectedCages} = this.state;
        let disabledCounter = 0;
        for (let cage of selectedCages) {
            let shadow = shadows.get(cage.DevID);
            if (!shadow) disabledCounter++;
        }
        return disabledCounter === selectedCages.length;
    }

    getName(cage) {
        const {Alias} = cage;
        const {showAlias} = this.state;
        if (showAlias) {
            if (Alias) return Alias;
        }
        let cageLocations = cage.getLocation();
        let location = cageLocations.map(loc => getLocationName(loc)).join(", ");
        if (location) return location;
        return `${cage.Address}/${cage.Address.toString(16)} ${getInterfName(cage.Interface)}`;
    }

    sortDevices = (a, b) => {
        let nameA = this.getName(a);
        let nameB = this.getName(b);
        return nameA.localeCompare(nameB);
    };

    shouldDisableStartSettlement() {
        if(isUsingFakeData()) return true;
        const {selectedCages} = this.state;
        const {settlements} = this.props;
        for (let cage of selectedCages) {
            let location = cage.getLocation();
            let locID = getLocationID(location[0]);
            if (!locID) return true;
            let settlement = settlements.find(s => s.PlcmntID === locID && (!s.DtaEndTime || s.DtaEndTime > new Date().getTime()));
            if (settlement) {
                if (cage.Settings.Insertions) {
                    let shouldDisable = !!cage.Settings.Insertions.find(item => item.StartTime === settlement.DtaStartTime);
                    if (shouldDisable) return true;
                } else {
                    return true;
                }
            }
        }
    }

    shouldDisableEndSettlement() {
        if(isUsingFakeData()) return false;
        const {selectedCages} = this.state;
        const {settlements} = this.props;
        for (let cage of selectedCages) {
            let location = cage.getLocation();
            let locID = getLocationID(location[0]);
            if (!locID) return true;
            let settlement = settlements.find(s => s.PlcmntID === locID && !s.DtaEndTime);
            if (settlement && cage.Settings.Insertions) {
                let shouldDisable = !cage.Settings.Insertions.find(item => item.StartTime === settlement.DtaStartTime);
                if (shouldDisable) return true;
            } else {
                return true;
            }
        }
    }

    onEndInsertionSuccess = () => {
        const {selectedCages} = this.state;
        let devices = [];
        for (let cage of selectedCages) {
            let copy = cage.prepareBeanToSave();
            copy.endLastInsertion(moment.utc().endOf("day").toDate().getTime());
            devices.push(copy);
        }
        updateDevices(devices);
    }

    render() {
        const {selectedCages, event} = this.state;
        const {cages, t, loading} = this.props;
        if (!cages.length) {
            return (
                <NoDataFoundLoading isLoading={loading} dataLength={cages.length}
                                    noDataText={t("farmView.cage.cagesNotFound")}/>
            )
        }
        cages.sort(this.sortDevices);
        let disabledOption = this.checkIfDisabled();
        return (
            <>
                {
                    cages.map(cage => <Cage cage={cage} key={cage.DevID}
                                            selected={selectedCages.filter(item => item.DevID === cage.DevID).length > 0}
                                            onSelect={this.onCageSelected} onRightClick={this.onRightClick}/>)
                }
                <ButtonGroup fixed>
                    <Menu event={event}>
                        <MenuItem icon={<i className="fas fa-undo fa-fw"/>} onClick={this.onResetClick}
                                  disabled={disabledOption}>
                            {t("reset")}
                        </MenuItem>
                        <MenuItem icon={<i className="fas fa-stop fa-fw"/>} onClick={this.onStopClick}
                                  disabled={disabledOption}>
                            {t("stop")}
                        </MenuItem>
                        <MenuItem icon={<i className="fas fa-cog fa-fw"/>} onClick={this.onSetWorktypeClick}
                                  disabled={disabledOption}>
                            {t("farmView.cage.changeWorkType")}
                        </MenuItem>
                        <MenuItem icon={<i className="fas fa-arrow-up fa-fw"/>}
                                  onClick={this.onAddNewInsertion}
                                  disabled={this.shouldDisableStartSettlement()}>
                            {t("farmView.cage.newInsertion")}
                        </MenuItem>
                        <MenuItem icon={<i className="fas fa-stop-circle fa-fw"/>}
                                  onClick={this.onEndCurrentInsertion}
                                  disabled={this.shouldDisableEndSettlement()}>
                            {t("farmView.cage.endInsertion")}
                        </MenuItem>
                        <MenuItem icon={<i className="fas fa-highlighter"/>}
                                  onClick={this.onCageMarkerClick} disabled={disabledOption}>
                            Marker
                        </MenuItem>
                        <MenuItem icon={<i className="fas fa-recycle"/>}
                                  onClick={this.onClearErrorsClick} disabled={disabledOption}>
                            {t("farmView.cage.clearErrors")}
                        </MenuItem>
                    </Menu>
                    <InfoBox boxColor={"info"}>
                        {t('farmView.cage.selectedCages', {count: selectedCages.length})}
                    </InfoBox>
                    <Button buttonColor={"info"} buttonStyle={"round"} icon={<i className="fas fa-ellipsis-v"/>}
                            onClick={this.onButtonClick} disabled={selectedCages.length === 0}/>
                </ButtonGroup>
                <CageSetWorkTypeModal cages={this.state.selectedCages}/>
                <CreateInsertionModal cages={this.state.selectedCages}/>
                <CageMarkerModal cages={this.state.selectedCages}/>
                <CageExpandDataModal/>
                <CompareInsertionsModal/>
                <CompareDayDataModal/>
            </>
        );
    }

}

SeparationCageTab.propTypes = {
    cages: PropTypes.array.isRequired
};


export let _SeparationCageTab = connect(
    state => ({
        shadows: state.shadows.shadows,
        loading: state.dataLoader.loading,
        settlements: getNewestSettlements(state)
    }),
    dispatch => bindActionCreators({show}, dispatch)
)(SeparationCageTab);
_SeparationCageTab = withTranslation()(_SeparationCageTab);

export default withRoles({roles: [Roles._DEVICE_CAGE], showInfo: true})(_SeparationCageTab);
