import {setFilters} from "../../../../../actions/taskActions";
import {TaskStatus} from "../../../../../constans/task";

export function submit(values, formikBag) {
    const filters = {};
    if (values.todo || values.done) {
        filters.Status = {$or: []};
        if (values.todo) filters.Status.$or.push(TaskStatus.OPENED);
        if (values.done) filters.Status.$or.push(TaskStatus.DONE);
    }
    if (values.hasOwnProperty("Title") && values.Title.length > 0) {
        filters.Title = {$regex: values.Title};
    }
    if (values.hasOwnProperty("AssignTo")) {
        filters.AssignTo = values.AssignTo;
    }
    if (values.hasOwnProperty("Priority") && values.Priority !== null) {
        filters.Priority = values.Priority;
    }
    if (values.hasOwnProperty("EstimatedTime") && values.EstimatedTime !== null) {
        filters.EstimatedTime = values.EstimatedTime;
    }
    formikBag.props.dispatch(setFilters(filters));
    formikBag.props.onHide();
}