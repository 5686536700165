import React, {Component} from 'react';
import {connect} from 'react-redux';
import List from "../../components/basics/list/List";
import Header from "../../components/basics/header/Header";
import ViewContainer from "../../components/basics/view-container/ViewContainer";
import Card from "../../components/basics/card/Card";
import {CellMeasurer, CellMeasurerCache, WindowScroller} from "react-virtualized";
import {List as VirtualizedList} from "react-virtualized/dist/commonjs/List";
import {getRemToPx} from "../../utils/DOMUtils";
import {Row} from "react-bootstrap";
import {get} from "lodash";
import {withTranslation} from "react-i18next";
import NotificationItem from "../../components/new-notifications-components/NotificationItem";
import {ReactLocalStorage} from "../../utils/ReactLocalStorage";
import {getNotificationsDynamoDB, setReadTime} from "../../actions/notificationsActions";

function mapStateToProps(state) {
    return {
        notifications: state.notificationCenter.notifications,
        fetching: state.notificationCenter.fetching,
        farm: state.location.farm,

    };
}

class NotificationsView extends Component {

    cache = new CellMeasurerCache({
        defaultHeight: getRemToPx(3),
        fixedWidth: true,
        keyMapper: (rowIndex) => get(this, `props.notifications[${rowIndex}].NID`, rowIndex)
    });

    componentDidMount() {
        if (1) return false;
        const {dispatch, farm} = this.props;
        dispatch(getNotificationsDynamoDB(farm));
    }

    componentWillUnmount() {
        const now = +new Date();
        this.props.dispatch(setReadTime(now));
    }

    clearCache = () => {
        console.log("clear cache");
        this.cache.clearAll();
    };

    rowRenderer = ({index, style, key, parent}) => {
        const {notifications} = this.props;
        const notification = notifications[index] || {};
        return (
            <CellMeasurer
                cache={this.cache}
                columnIndex={0}
                key={key}
                parent={parent}
                rowIndex={index}
            >
                {({measure}) => (
                    <NotificationItem style={style} notification={notification} key={index} index={index}
                                      measure={measure}/>
                )}
            </CellMeasurer>
        );
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.notifications.length !== prevProps.notifications.length) {
            this.clearCache();
        }
    }

    noRowsRenderer = () => {
        const {fetching, t} = this.props;
        return fetching ? <div className={"mh-5rem pb-4 pt-4 d-flex justify-content-center w-100 align-items-center"}>
            <i className={"fas fa-fw fa-spin fa-circle-notch fa-2x"}/>
        </div> : <div className={"mh-5rem pb-4 pt-4 d-flex justify-content-center opacity-50 w-100 align-items-center"}>
            <h4><span>{t("notificationsNotFound")}</span></h4>
        </div>
    }

    render() {
        const {notifications, fetching, t} = this.props;
        console.log(ReactLocalStorage, "storage wrapper");
        return (
            <div>
                <Header text={t("notifications.notifications")}/>
                <ViewContainer>
                    <Card className={"min-vh-25"}>
                        <Row className={"position-relative background-inherit"}>
                            <WindowScroller onResize={this.clearCache}>
                                {
                                    ({height, isScrolling, scrollTop, onChildScroll, registerChild}) =>
                                        <List itemRef={registerChild} useFlexLayout={false}
                                              striped={true}>
                                            <VirtualizedList
                                                rowCount={notifications.length}
                                                width={window.innerWidth}
                                                rowRenderer={this.rowRenderer}
                                                scrollTop={scrollTop}
                                                data={notifications}
                                                autoHeight
                                                fetching={fetching}
                                                height={height}
                                                deferredMeasurementCache={this.cache}
                                                rowHeight={this.cache.rowHeight}
                                                isScrolling={isScrolling}
                                                onScroll={onChildScroll}
                                                className={"w-100 overflow-auto"}
                                                noRowsRenderer={this.noRowsRenderer}
                                            />
                                        </List>
                                }
                            </WindowScroller>
                        </Row>
                    </Card>
                </ViewContainer>
            </div>
        );
    }
}

NotificationsView = connect(
    mapStateToProps,
)(NotificationsView);
export default withTranslation()(NotificationsView)
