import React, {Component} from 'react';
import {taskPriorityValueFormatter} from "../../../../utils/taskUtils";
import {TaskStatus} from "../../../../constans/task";
import {withTranslation} from "react-i18next";

class TaskListMobileRow extends Component {

    onClick = () => {
        this.props.onMobileClick(this.props.object);
    }

    render() {
        const {object: {Status, Title, Priority, EstimatedTime, AssignTo}, employeeFormatter, t} = this.props;
        return (
            <div className="task-item-mobile" onClick={this.onClick}>
                {
                    Status === TaskStatus.DONE &&
                    <div className="task-done">
                        <i className="fas fa-check success"/>
                    </div>
                }
                <div>
                    <h4>{Title}</h4>
                    <div>{t("eventGrid.operator")}: {employeeFormatter(AssignTo)}</div>
                    <div>{t("priority")}: {taskPriorityValueFormatter(Priority)}</div>
                    <div>{t("estimatedTime")}: {EstimatedTime}h</div>
                </div>
                <i className="fas fa-chevron-right"/>
            </div>
        );
    }
}

export default withTranslation()(TaskListMobileRow);