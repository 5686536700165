import React, {Component} from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import PigletScale from "../../../beans/devices/PigletScale";
import PigletScaleRow from "../../../components/devices-rows/piglet-scale/PigletScaleRow";
import ButtonGroup from "../../../components/basics/button/button-group/ButtonGroup";
import Menu from "../../../components/basics/menu/Menu";
import MenuItem from "../../../components/basics/menu/MenuItem";
import InfoBox from "../../../components/basics/info-box/InfoBox";
import Button from "../../../components/basics/button/Button";
import {bindActionCreators, compose} from "redux";
import {withTranslation} from "react-i18next";
import {show} from "redux-modal";
import ChamberSettlementWeightModal, {ModalName as ChamberSettlementWeightModalName,} from "../../../components/modals-new/chamber-settlement-weight-modal/ChamberSettlementWeightModal";
import {getLocationID} from "../../../utils/BuildingUtils";
import PigletScaleSettingsModal, {ModalName as PigletScaleSettingsModalName} from "../../../components/modals-new/piglet-scale-settings-modal/PigletScaleSettingsModal";
import {checkIfUserIsService} from "../../../utils/NewRolesUtils";
import {ModalName as ConfirmModalName} from "../../../components/modals-new/confirm-modal/ConfirmModal";
import {
    createEndInsertionNotificationFailure,
    createEndInsertionNotificationSuccess,
    endSettlementForDevice
} from "../../../api/feeding/Settlement";
import NoDataFoundLoading from "../../../components/no-data-found-farm-view/NoDataFoundLoading";
import withRoles from "../../../components/withRoles";
import * as RoleTypes from "validators-schema/Api/constants/roleTypes";

function mapStateToProps(state) {
    return {
        farm: state.location.farm,
        settlements: state.settlement.settlement,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        ...bindActionCreators({show}, dispatch),
        dispatch,
    };
}

class PigletScaleTab extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedPigletScales: [],
            event: null
        };
    }

    onButtonClick = e => {
        this.setState({
            event: e,
        });
    };

    onSelect = scale => {
        let selectedPigletScales = this.state.selectedPigletScales.slice(0);
        if (selectedPigletScales.filter(item => item.DevID === scale.DevID).length > 0) {
            selectedPigletScales = selectedPigletScales.filter(item => item.DevID !== scale.DevID);
        } else {
            selectedPigletScales.push(scale);
        }
        this.setState({
            selectedPigletScales,
        });
    };

    onRightClick = (e, scale) => {
        this.setState({
            event: e,
            selectedPigletScales: [scale],
        });
    };

    onNewSettlementClick = () => {
        this.props.show(ChamberSettlementWeightModalName, {scales: this.state.selectedPigletScales});
    };

    onEndSettlementClick = () => {
        const {t} = this.props;
        this.props.show(ConfirmModalName, {
            text: t("modals.chamberTransferModal.endInsertionText"),
            title: t("modals.chamberTransferModal.titleEndInsertion"),
            confirmText: t("yes"),
            onConfirmed: props => {
                let promises = [];
                for (let scale of this.state.selectedPigletScales) {
                    promises.push(endSettlementForDevice({DevID: scale.DevID}));
                }
                Promise.all(promises).then(res => {
                    createEndInsertionNotificationSuccess(res);
                }).catch(e => {
                    createEndInsertionNotificationFailure(e);
                });
                props.handleHide();
            }
        })
    };

    checkIfDisableStart() {
        const {selectedPigletScales} = this.state;
        const {settlements} = this.props;
        for (let scale of selectedPigletScales) {
            let location = scale.getLocation();
            if (location.length === 0) return true;
            let id = getLocationID(location[0]);
            if (!id) return true;
            let settlement = settlements.find(s => s.PlcmntID === id && (!s.DtaEndTime || s.DtaEndTime > new Date().getTime()));
            if (settlement) {
                if (scale.Settings.Insertions) {
                    let shouldDisable = !!scale.Settings.Insertions.find(item => item.StartTime === settlement.DtaStartTime);
                    if (shouldDisable) return true;
                } else {
                    return true;
                }
            }
        }
        return false;
    }

    checkIfDisableEnd() {
        const {selectedPigletScales} = this.state;
        const {settlements} = this.props;
        for (let scale of selectedPigletScales) {
            let location = scale.getLocation();
            if (location.length === 0) return true;
            let id = getLocationID(location[0]);
            if (!id) return true;
            let settlement = settlements.find(s => s.PlcmntID === id && !s.DtaEndTime);
            if (settlement && scale.Settings.Insertions) {
                let shouldDisable = !scale.Settings.Insertions.find(item => item.StartTime === settlement.DtaStartTime);
                if (shouldDisable) return true;
            } else {
                return true;
            }
        }
        return false;
    }

    onSettingsClick = () => {
        this.props.show(PigletScaleSettingsModalName, {scales: this.state.selectedPigletScales});
    };

    render() {
        const {selectedPigletScales, event} = this.state;
        const {scales, t, loading} = this.props;
        if (!scales.length) {
            return (
                <NoDataFoundLoading dataLength={scales.length} isLoading={loading}
                                    noDataText={t("farmView.pigletScale.pigletScalesNotFound")}
                />
            )
        }
        return (
            <>
                {scales.map(scale => (
                    <PigletScaleRow
                        scale={scale}
                        key={scale.DevID}
                        selected={selectedPigletScales.filter(item => item.DevID === scale.DevID).length > 0}
                        onSelect={this.onSelect}
                        onRightClick={this.onRightClick}
                    />
                ))}
                <ButtonGroup fixed>
                    <Menu event={event}>
                        <MenuItem
                            icon={<i className="fas fa-arrow-up fa-fw"/>}
                            onClick={this.onNewSettlementClick}
                            disabled={this.checkIfDisableStart()}>
                            {t("settlement")}
                        </MenuItem>
                        <MenuItem
                            icon={<i className="fas fa-stop-circle fa-fw"/>}
                            onClick={this.onEndSettlementClick}
                            disabled={this.checkIfDisableEnd()}>
                            {t("transfer")}
                        </MenuItem>
                        {
                            checkIfUserIsService() &&
                            <MenuItem
                                icon={<i className="fas fa-cog fa-fw"/>}
                                onClick={this.onSettingsClick}>
                                {t("settings.settings")}
                            </MenuItem>
                        }
                    </Menu>
                    <InfoBox boxColor={"info"}>
                        {t("farmView.pigletScale.selectedPigletScales", {count: selectedPigletScales.length})}
                    </InfoBox>
                    <Button
                        buttonColor={"info"}
                        buttonStyle={"round"}
                        icon={<i className="fas fa-ellipsis-v"/>}
                        onClick={this.onButtonClick}
                        disabled={selectedPigletScales.length === 0}
                    />
                </ButtonGroup>
                <ChamberSettlementWeightModal/>
                <PigletScaleSettingsModal/>
            </>
        );
    }
}

PigletScaleTab.propTypes = {
    scales: PropTypes.arrayOf(PropTypes.instanceOf(PigletScale)).isRequired,
};

export default compose(
    withRoles({roles: [RoleTypes.DEVICE_PIGLET_CAGE], showInfo: true}),
    withTranslation(),
    connect(mapStateToProps, mapDispatchToProps),
)(PigletScaleTab);
