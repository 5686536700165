import React from "react";
import {connect} from "react-redux";
import moment from "moment";
import {cloneDeep, get, isEqual, isNil} from "lodash";
import LabeledSelect from "../../../components/basics/select/labeled-select/LabeledSelect";
import {getFarmStart} from "../../../utils/SettingsUtils";
import Chart from "../../../components/basics/chart/Chart";
import {prepareResultsGrid} from "../../../actions/gridActions";
import NotFound from "../../../components/NotFound";
import LoadingComponent from "../../../components/loading/LoadingComponent";
import Button from "../../../components/basics/button/Button";
import ButtonGroup from "../../../components/basics/button/button-group/ButtonGroup";
import Setting from "../../../beans/Setting";
import {SettingTypes} from "../../../constans/settingTypes";
import {createSettingDynamoDB, updateSettingDynamoDB} from "../../../actions/settingsAction";
import {withTranslation} from "react-i18next";

class ResultsChart extends React.Component {

    constructor(props) {
        super (props);
        this.state = {
            planBefore: [],
            plan: [],
            year: moment().year(),
            chartType: undefined,
            loading: false
        }
    }

    componentDidMount() {
        const {t} = this.props;
        const {year} = this.state;
        const type = t("planTypes.inseminatedRenovationSows");
        this.setState({
            chartType: type,
            data: this.calculateData({props: this.props, value: type, year: year})
        });
    }

    calculateWorkerData(year) {
        let plan = [], before = [];
        let fromTime = getFarmStart();
        let weeks = 1;
        if (this.props.plan) {
            this.props.plan.SetData.Plans.map(p => {
                if (p.Year === year) plan = p.Data;
                if (p.Year === year - 1) before = p.Data
            });
        }
        this.props.dispatch(prepareResultsGrid(this.props.farm, cloneDeep(this.props.resultsGrid), plan, before, fromTime, weeks, year));
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if (this.props.loading !== nextProps.loading) {
            setTimeout(() => {
                this.setState({
                    loading: nextProps.loading
                })
            }, 100)
        }

        if (this.props.lang !== nextProps.lang) {
            this.calculateWorkerData(this.state.year);
        }

        if (!isEqual(this.props.resultsGrid, nextProps.resultsGrid) && nextProps.resultsGrid.length > 0) {
            let planBefore = nextProps.resultsGrid.map(val => !val.isFullWidth ? val.before || 0 : null);
            let plan = nextProps.resultsGrid.map(val => !val.isFullWidth ? val.plan || 0 : null);
            const type = nextProps.t(nextProps.resultsGrid[0].name);
            const {year} = this.state;
            this.setState({
                chartType: type,
                planBefore,
                plan,
                data: this.calculateData({props: nextProps, value: type, year: year})
            });
        }
    }

    calculateData({props, value, year}) {
        const {resultsGrid, t} = props;
        let array = [];
        if (resultsGrid.length !== 0) {
            for (let i = 0; i < moment().year(year).weeksInYear(); i++) {
                let row = resultsGrid.find(r => t(r.name) === value);
                array.push({
                    amount: !isNil(row) ? row["week" + (i + 1)] : 0,
                    week: i + 1,
                });
            }
        }
        return array;
    }

    getYears() {
        let fromTime = getFarmStart();
        let array = [];
        for (let i = fromTime.year(); i <= moment().year(); i++) {
            array.push({
                name: i,
                value: i
            })
        }
        return array;
    }

    getResultsOptions() {
        const {resultsGrid, t} = this.props;
        const array = [];
        for (let i = 0; i < resultsGrid.length; i++) {
            const isFullWidth = get(resultsGrid[i], "isFullWidth", false);
            if (!isFullWidth) {
                array.push({
                    name: t(resultsGrid[i].name),
                    value: t(resultsGrid[i].name)
                });
            }
        }
        return array;
    }

    onNameChange = value => {
        const { year } = this.state;
        this.setState({
            chartType: value,
            data: this.calculateData({props: this.props, value: value, year: year})
        });
    };

    onYearChange = value => {
        this.setState({
            year: value
        });
        this.calculateWorkerData(value);
    };

    onButtonClick = () => {
        const {plan} = this.props;
        if (!plan) {
            let plan = Setting.createSetting(SettingTypes.YEARLY_PLANS, {
                Plans: [
                    {
                        Data: this.state.plan.filter(item => item !== null),
                        Year: this.state.year
                    },
                    {
                        Data: this.state.planBefore.filter(item => item !== null),
                        Year: this.state.year - 1
                    }
                ]
            });
            this.props.dispatch(createSettingDynamoDB(plan));
        } else {
            let copy = plan.clone();
            copy.setPlan(this.state.year, this.state.plan.filter(item => item !== null));
            copy.setPlan(this.state.year - 1, this.state.planBefore.filter(item => item !== null));
            this.props.dispatch(updateSettingDynamoDB(copy.prepareBeanToSave()));
        }
    };

    render() {
        const {year, chartType, loading} = this.state;
        const {resultsGrid, t} = this.props;
        let week = year === moment().year() ? moment().week() : 0;

        return (
            <div>
                {
                    (resultsGrid.length === 0 || !chartType) ? <div> <LoadingComponent isLoading={loading}/> <NotFound/> </div> :
                        <div>
                            <LoadingComponent isLoading={loading}/>
                            <LabeledSelect label={t("year")} options={this.getYears()}
                                           onChange={this.onYearChange} value={year} clearButton={false}/>
                            <LabeledSelect label={t("designation")}
                                           options={this.getResultsOptions()}
                                           onChange={this.onNameChange}
                                           value={chartType}
                                           clearButton={false}
                            />
                            <Chart
                                data={this.state.data}
                                dataDef={[{
                                    name: chartType,
                                    dataKey: "amount",
                                    color: "green",
                                    opacity: 0.2
                                }]}
                                height={"35rem"}
                                Xaxis={{
                                    dataKey: "week",
                                    name: t("farms.tabs.resultsTab.weeksAmount"),
                                    offset: -50
                                }}
                                Yaxis={{
                                    position: "insideTopRight",
                                    offset: -10,
                                    dataKey: "amount",
                                    name: chartType,
                                }}
                                referenceLines={[{
                                    x: week,
                                    name: t("farms.tabs.resultsTab.currentWeek"),
                                    color: "blue",
                                    position: week < 6 ? "insideTopLeft" : "insideTopRight"
                                }]}
                            />
                            <ButtonGroup fixed renderInPortal>
                                <Button
                                    buttonColor="success"
                                    buttonStyle="round"
                                    icon={<i className="fas fa-save"/>}
                                    onClick={this.onButtonClick}
                                />
                            </ButtonGroup>
                        </div>
                }
            </div>
        )
    }
}

ResultsChart = connect(state => ({
    farm: state.location.farm,
    resultsGrid: state.grid.resultsGridData,
    loading: state.grid.loadingResultsGrid,
    plan: state.settings.plan,
    lang: state.language.lang.lang,
}))(ResultsChart);

ResultsChart = withTranslation()(ResultsChart);

export default ResultsChart;
