import React from "react";
import Button from "../../../components/basics/button/Button";
import {DevType} from "../../../constans/devices";
import Menu from "../../../components/basics/menu/Menu";
import MenuItem from "../../../components/basics/menu/MenuItem";
import ButtonGroup from "../../../components/basics/button/button-group/ButtonGroup";
import InfoBox from "../../../components/basics/info-box/InfoBox";
import {withRouter} from "react-router-dom";
import ReactGA from "react-ga";
import ALink from "../../../components/basics/alink/ALink";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {getFarmMap} from "../../../selectors/mapSelector";
import {makeGetDevicesByType} from "../../../selectors/deviceSelector";
import DeviceFarmMap from "../../../components/farm-map-components/DeviceFarmMap/DeviceFarmMap";
import BuildingFarmMap from "../../../components/farm-map-components/BuildingFarmMap/BuildingFarmMap";


function makeMapStateToProps() {
    return function mapStateToProps(state) {
        const getDevicesByType = makeGetDevicesByType();
        return {
            farm: state.location.farm,
            levels: getFarmMap(state),
            devices: getDevicesByType(state, {DevType: [DevType.SCALE, DevType.CLIMATE]})
        }
    };
}

export class MapTab extends React.Component {

    constructor(props) {
        super(props);
        const {devices} = this.props;
        this.state = {
            mapType: devices.length ? "devices" : "buildings"
        };
        ReactGA.modalview("farm/general");
    }


    handleDevicesClick = () => {
        this.setState({
            mapType: "devices"
        })
    }

    handleBuildingsClick = () => {
        this.setState({
            mapType: "buildings"
        })
    }

    getIndex = () => {
        const {selectedLevel = 0} = this.state;
        const {levels = []} = this.props;
        return levels.findIndex(o => +o.name === +selectedLevel);
    }

    setIndex = (index = 0) => {
        const {levels = []} = this.props;
        if (levels[index]) {
            this.setState({
                selectedLevel: +levels[index].name
            })
        }
    }

    goUp = () => {
        const index = this.getIndex();
        this.setIndex(index + 1);
    }

    goDown = () => {
        const index = this.getIndex();
        this.setIndex(index - 1);
    }

    render() {
        const {mapType, selectedLevel = 0, event} = this.state;
        const {t, levels, farm, devices} = this.props;
        if (!levels.length) {
            return (
                <div>
                    <InfoBox boxColor={"warning"}>
                        {t("farms.tabs.mapTab.mapNotFound")} <ALink href={`/${farm}/settings/buildings/map`}
                                                                    bolded>{t("here")}</ALink> {t("farms.tabs.mapTab.toAddIt")}
                    </InfoBox>
                </div>
            )
        }
        const index = this.getIndex();
        return (
            <div>
                {
                    !!devices.length &&
                    <Menu event={event}>
                        <MenuItem onClick={this.handleBuildingsClick}>
                            {t("chambers")}
                        </MenuItem>
                        <MenuItem onClick={this.handleDevicesClick}>
                            {t("farms.tabs.mapTab.devices")}
                        </MenuItem>
                    </Menu>
                }

                <ButtonGroup fixed renderInPortal={true}>
                    <InfoBox>
                        {t("level")} {selectedLevel}
                    </InfoBox>
                    <>
                        {
                            levels.length > 1 &&
                            <>
                                <Button type={"button"} icon={<i className="fas fa-arrow-up"/>}
                                        disabled={(index + 1) >= levels.length}
                                        onClick={this.goUp} buttonColor={"info"}
                                        buttonStyle={"round"}/>
                                <Button type={"button"} icon={<i className="fas fa-arrow-down"/>}
                                        disabled={index <= 0}
                                        onClick={this.goDown} buttonColor={"info"}
                                        buttonStyle={"round"}/>
                            </>
                        }
                        {
                            !!devices.length &&
                            <Button type={"button"} icon={<i className="fas fa-ellipsis-v"/>}
                                    onClick={(ev) => this.setState({event: ev})} buttonColor={"info"}
                                    buttonStyle={"round"}/>
                        }
                    </>

                </ButtonGroup>
                {
                    mapType === "devices" &&
                    <DeviceFarmMap selectedLevel={selectedLevel}/>
                }
                {
                    mapType === "buildings" &&
                    <BuildingFarmMap selectedLevel={selectedLevel}/>
                }

            </div>
        );
    }
}

MapTab = withRouter(MapTab);

MapTab = connect(makeMapStateToProps)(MapTab);

export default withTranslation()(MapTab);
