import React, {Component} from 'react';
import ReactDOM from "react-dom";
import {connect} from 'react-redux';
import {withTranslation} from "react-i18next";
import {compose} from "redux";
import PropTypes from "prop-types";
import {Fade} from "react-bootstrap";
import "./_task-filter.scss";
import Checkbox from "../../../../../components/basics/checkbox/Checkbox";
import LabeledSelect from "../../../../../components/basics/select/labeled-select/LabeledSelect";
import LabeledInput from "../../../../../components/basics/input/labeled-input/LabeledInput";
import Button from "../../../../../components/basics/button/Button";
import {withFormik} from "formik";
import {submit} from "./TaskFilterSubmit";
import {isMobile} from "../../../../../utils/MobileUtils";
import ViewContainerModal from "../../../../../components/basics/view-containter-modal/ViewContainerModal";
import Card from "../../../../../components/basics/card/Card";

function mapStateToProps(state) {
    return {
        farm: state.location.farm
    };
}

class TaskFilter extends Component {

    state = {
        top: 0,
        left: 0,
    }

    container = React.createRef();

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.event) {
            let top = nextProps.event.clientY;
            let left = nextProps.event.clientX;
            if (top !== prevState.top || left !== prevState.left) {
                return {top, left};
            }
        }
        return null;
    }

    componentDidMount() {
        document.addEventListener("mousedown", this.onOutsideClick);
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.onOutsideClick);
    }

    onOutsideClick = e => {
        if (this.container.current && !this.container.current.contains(e.target)) {
            this.props.onHide();
        }
    }

    onChange(value, name) {
        this.props.setFieldValue(name, value);
    }

    renderForm(mobile = false) {
        const {t, values, handleSubmit} = this.props;
        const {top, left} = this.state;
        return (
            <form style={!mobile ? {top, left} : null} ref={this.container} onSubmit={handleSubmit}
                  className={`task-filter${mobile ? " mobile" : ""}`}>
                <Card>
                    <h6>Filtry</h6>
                    <div className="filter-group">
                        <Checkbox label={t("toDo")} checked={values.todo}
                                  onChange={value => this.onChange(value, "todo")}/>
                        <Checkbox label={t("taskDone")} checked={values.done}
                                  onChange={value => this.onChange(value, "done")}/>
                    </div>
                    <LabeledInput label={t("task")} type={"text"} value={values.Title}
                                  onChange={value => this.onChange(value, "Title")}/>
                    <LabeledSelect label={t("eventGrid.operator")} options={[]} value={values.AssignTo}
                                   onChange={value => this.onChange(value, "AssignTo")}/>
                    <label>{t("priority")}</label>
                    <div className="filter-group">
                        <Button onClick={() => this.onChange(values.Priority === 1 ? null : 1, "Priority")}
                                buttonColor={values.Priority === 1 ? "success" : ""}
                                type={"button"}>{t("morning")}</Button>
                        <Button onClick={() => this.onChange(values.Priority === 2 ? null : 2, "Priority")}
                                buttonColor={values.Priority === 2 ? "success" : ""}
                                type={"button"}>{t("noon")}</Button>
                        <Button onClick={() => this.onChange(values.Priority === 3 ? null : 3, "Priority")}
                                buttonColor={values.Priority === 3 ? "success" : ""}
                                type={"button"}>{t("afternoon")}</Button>
                    </div>
                    <label>{t("estimatedTime")}</label>
                    <div className="filter-group">
                        <Button
                            onClick={() => this.onChange(values.EstimatedTime === 0.5 ? null : 0.5, "EstimatedTime")}
                            buttonColor={values.EstimatedTime === 0.5 ? "success" : ""} type={"button"}>0,5h</Button>
                        <Button onClick={() => this.onChange(values.EstimatedTime === 1 ? null : 1, "EstimatedTime")}
                                buttonColor={values.EstimatedTime === 1 ? "success" : ""} type={"button"}>1h</Button>
                        <Button onClick={() => this.onChange(values.EstimatedTime === 2 ? null : 2, "EstimatedTime")}
                                buttonColor={values.EstimatedTime === 2 ? "success" : ""} type={"button"}>2h</Button>
                        <Button onClick={() => this.onChange(values.EstimatedTime === 4 ? null : 3, "EstimatedTime")}
                                buttonColor={values.EstimatedTime === 4 ? "success" : ""} type={"button"}>3h</Button>
                    </div>
                    {
                        !mobile &&
                        <div className="filter-save">
                            <Button buttonColor={"success"}>{t("filterGrid.filter")}</Button>
                        </div>
                    }
                </Card>
                {
                    mobile &&
                    <div className="filter-save mobile">
                        <Button buttonColor={"success"}>{t("filterGrid.filter")}</Button>
                    </div>
                }
            </form>
        )
    }

    render() {
        const {event, t} = this.props;
        if (isMobile()) {
            return (
                <ViewContainerModal name={t("grid.filterOoo")} show={!!event} className="filter-view-container">
                    {this.renderForm(true)}
                </ViewContainerModal>
            )
        }
        return ReactDOM.createPortal(
            <Fade in={!!event} mountOnEnter unmountOnExit>
                {this.renderForm()}
            </Fade>
            , document.getElementById("root"));
    }
}

TaskFilter.propTypes = {
    show: PropTypes.bool,
    onHide: PropTypes.func.isRequired
}

export default compose(
    withTranslation(),
    connect(mapStateToProps),
    withFormik({
        mapPropsToValues: () => ({todo: true}),
        handleSubmit: submit
    })
)(TaskFilter);
