import React from "react";
import PropTypes from "prop-types"
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {show} from "redux-modal";
import withRoles from "../../../components/withRoles";
import {Roles} from "../../../constans/roles";
import NewIOT from "../../../IOT/NewIOT";
import {getDeviceChamberMap, groupDevicesByGatewayID} from "../../../utils/DevicesUtils";
import {DevType} from "../../../constans/devices";
import NoDataFoundLoading from "../../../components/no-data-found-farm-view/NoDataFoundLoading";
import WaterFlowMeter from "../../../components/devices-rows/water/WaterFlowMeter";
import WaterMeterAlarmModal, {ModalName as WaterMeterAlarmModalName} from "../../../components/modals-new/water-meter-alarm-modal/WaterMeterAlarmModal";
import ButtonGroup from "../../../components/basics/button/button-group/ButtonGroup";
import Menu from "../../../components/basics/menu/Menu";
import MenuItem from "../../../components/basics/menu/MenuItem";
import InfoBox from "../../../components/basics/info-box/InfoBox";
import Button from "../../../components/basics/button/Button";
import _ from "lodash";
import {ChamberSettlement, getNewestSettlements, settlementCondition} from "../../../selectors/settlementSelector";
import ChamberSettlementModal, {ModalName as ChamberSettlementModalName} from "../../../components/modals-new/chamber-settlement-modal/ChamberSettlementModal";
import ChamberTransferModal, {ModalName as ChamberTransferModalName} from "../../../components/modals-new/chamber-transfer-modal/ChamberTransferModal";
import {withTranslation} from "react-i18next";
import sinon from 'sinon';
import {isUsingFakeData} from "../../../utils/SettingsUtils";
import {
    fakeCreateAndSendMessageObject,
    fakeStartSendingDeviceState,
    refresh
} from "../../../demo/water-meter/fakeFunctions";
import i18next from "i18next";
import buildingsDB from "../../../database/buildingsDB";


export class WaterTab extends React.Component {

    constructor(props) {
        super(props);
        this.devicePolling = [];
        this.state = {
            selected: [],
            event: null
        }
        //wersja demo
        const useFakeData = isUsingFakeData();
        if (useFakeData) {
            sinon.restore();
            sinon.stub(NewIOT, "startSendingDeviceState").callsFake(fakeStartSendingDeviceState);
            sinon.stub(NewIOT, "createAndSendMessageObject").callsFake(fakeCreateAndSendMessageObject);
            sinon.stub(NewIOT, "sendRequestForAggregatedData").callsFake(() => {
            });
            this.timer = setInterval(() => {
                refresh(this.props.waterFlowMeters)
            }, 5 * 1000)
        }
    }

    onSelect = device => {
        const {selected} = this.state;
        let selectedCopy = selected.slice(0);
        const index = selectedCopy.findIndex(item => item.DevID === device.DevID);
        if (index === -1) {
            selectedCopy.push(device);
        } else {
            selectedCopy.splice(index, 1);
        }
        this.setState({
            selected: selectedCopy
        })
    };

    onMenuOpen = event => {
        this.setState({
            event
        })
    };

    componentDidMount() {
        this.startPolling();
    }

    componentWillUnmount() {
        sinon.restore();
        //wylaczenie odpytywania
        this.clearPolling();
        clearInterval(this.timer);
    }

    clearPolling = () => {
        NewIOT.removeFromInterval(this.devicePolling);
        this.devicePolling = [];
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {waterFlowMeters} = this.props;
        if (waterFlowMeters.length !== prevProps.waterFlowMeters.length) {
            this.startPolling();
        }
    }

    startPolling = () => {
        const {waterFlowMeters} = this.props;
        //wyczyszczneie jesli odpytuje cos
        this.clearPolling();
        //pobranie danych
        let devices = groupDevicesByGatewayID(waterFlowMeters);
        if (devices) {
            for (let d of devices.values()) {
                if (d[DevType.WATER_FLOW_METER].length) {
                    this.devicePolling.push(NewIOT.startSendingDeviceState(d[DevType.WATER_FLOW_METER]));
                    NewIOT.sendRequestForAggregatedData(d[DevType.WATER_FLOW_METER])
                }
            }
        }
    };

    checkSelected = (selected, DevID) => {
        return !!~selected.findIndex(o => o.DevID === DevID);
    };

    openModal = (modalName, extraProps = {}) => {
        const {show} = this.props;
        show(modalName, {devices: this.state.selected, ...extraProps});
    };

    onAlarmsModalOpen = () => {
        this.openModal(WaterMeterAlarmModalName);
    };

    onSettlementModalOpen = (CID) => {
        this.openModal(ChamberSettlementModalName, {chamberId: CID})
    };

    onTransferModalOpen = (CID) => {
        this.openModal(ChamberTransferModalName, {chamberId: CID})
    };

    getSettlement = (map) => {
        const {selected} = this.state;
        const {settlements, fetched} = this.props;
        if (selected.length === 1) {
            const CID = _.get(map.get(selected[0].DevID), "CID");
            if (CID) {
                let placement = buildingsDB.getLocationByID(CID);
                if (!!placement && placement.BgID) {
                    return {state: ChamberSettlement.NO_SHOW};
                }
                return {CID: CID, state: settlementCondition(settlements.find(o => o.PlcmntID === CID), fetched)};
            }
        }
        return {state: ChamberSettlement.NO_SHOW}
    };

    render() {
        const {waterFlowMeters, t, loading, buildings} = this.props;
        const {event, selected} = this.state;
        const map = getDeviceChamberMap(buildings, waterFlowMeters);
        const {state, CID} = this.getSettlement(map);
        const menuOptions = [{
            icon: "fas fa-cog fa-fw",
            action: this.onAlarmsModalOpen,
            name: t("deviceRows.waterFlowMeter.waterTab.alarms")
        }, {
            icon: "fas fa-sign-in fa-rotate-270 fa-fw",
            action: () => this.onSettlementModalOpen(CID),
            name: t("settlement"),
            hide: state !== ChamberSettlement.SHOW_SETTLEMENT
        }, {
            icon: "fas fa-sign-out fa-rotate-270 fa-fw",
            action: () => this.onTransferModalOpen(CID),
            name: t("transfer"),
            hide: state !== ChamberSettlement.SHOW_SEPARATION

        }];

        if (!waterFlowMeters.length) {
            return (
                <NoDataFoundLoading isLoading={loading} dataLength={waterFlowMeters.length}
                                    noDataText={t("deviceRows.waterFlowMeter.waterTab.waterFlowMetersNotFound")}/>
            )
        }
        return (
            <>
                {
                    waterFlowMeters.map((device, index) => <WaterFlowMeter
                        {...(map.get(device.DevID) || {})}
                        device={device}
                        key={index}
                        selected={this.checkSelected(selected, device.DevID)}
                        onSelect={this.onSelect}
                        onMenuOpen={this.onMenuOpen}
                    />)
                }
                <ButtonGroup fixed>
                    <Menu event={event}>
                        {
                            menuOptions.map((menu, index) => (!menu.hide ?
                                    <MenuItem key={index} icon={<i className={menu.icon}/>}
                                              onClick={menu.action}>
                                        {menu.name}
                                    </MenuItem> : null
                            ))
                        }

                    </Menu>
                    <InfoBox boxColor={"info"}>
                        {i18next.t("deviceRows.waterFlowMeter.waterTab.selected", {count: selected.length})}
                    </InfoBox>
                    <Button buttonStyle={"round"} buttonColor={"info"}
                            type={"button"}
                            icon={<i className="fas fa-ellipsis-v"/>} disabled={selected.length === 0}
                            onClick={this.onMenuOpen}/>
                </ButtonGroup>
                <WaterMeterAlarmModal/>
                <ChamberSettlementModal/>
                <ChamberTransferModal/>
            </>
        );
    }

}

WaterTab.propTypes = {
    waterFlowMeters: PropTypes.shape({
        DevID: PropTypes.object.isRequired,
        GatewayID: PropTypes.object.isRequired
    }).isRequired
};

WaterTab = connect(
    null,
    dispatch => bindActionCreators({show}, dispatch)
)(WaterTab);
export let _WaterTab = connect(state => ({
    loading: state.dataLoader.loading,
    buildings: state.farms.buildings,
    settlements: getNewestSettlements(state),
    fetched: state.settlement.fetchedSettlement,
}))(WaterTab);

_WaterTab = withTranslation()(_WaterTab);

export default withRoles({roles: [Roles._DEVICE_COUNTER], showInfo: true})(_WaterTab);
