import React, {Component} from 'react';
import {TaskStatus} from "../../../../../constans/task";

class IsDoneRenderer extends Component {
    render() {
        const {value} = this.props;
        if (value !== TaskStatus.DONE) return null;
        return (
            <i className="fas fa-check success"/>
        );
    }
}

export default IsDoneRenderer;