import React from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {show} from "redux-modal";
import withRoles from "../../../components/withRoles";
import {Roles} from "../../../constans/roles";
import {withTranslation} from "react-i18next";
import {clear, setSelectedIDs} from "../../../actions/mainTabActions";
import {isString} from "lodash";
import SiloList from "../../../components/devices-rows/silo/SiloList";
import SiloButtons from "../../../components/devices-rows/silo/SiloButtons";


export class SiloTab extends React.Component {

    state = {
        event: null
    }


    openMenu = (e, {DevID} = {}) => {
        const {onSelect} = this.props;
        if (isString(DevID)) {
            onSelect(DevID)
        }
        this.setState({
            event: e
        })
    };

    componentDidMount() {
        this.props.clear();
    }

    componentWillUnmount() {
        this.props.clear();
    }

    render() {
        const {event} = this.state;
        return (
            <div>
                <SiloList openMenu={this.openMenu}/>
                <SiloButtons openMenu={this.openMenu} event={event}/>
            </div>
        );
    }

}

SiloTab = connect(
    null,
    dispatch => ({
        ...bindActionCreators({show, clear}, dispatch),
        onSelect: (id) => dispatch(setSelectedIDs("siloses", true, id))
    })
)(SiloTab);

export let _SiloTab = connect()(SiloTab);

_SiloTab = withTranslation()(_SiloTab);

export default withRoles({roles: [Roles._DEVICE_SILO], showInfo: true})(_SiloTab);
