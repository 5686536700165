import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withTranslation} from "react-i18next";
import {bindActionCreators, compose} from "redux";
import Card from "../../../../components/basics/card/Card";
import TableGrid from "../../../../components/basics/table-grid/TableGrid";
import Button from "../../../../components/basics/button/Button";
import "./_task-list.scss";
import IsDoneRenderer from "./renderers/IsDoneRenderer";
import Input from "../../../../components/basics/input/Input";
import moment from "moment";
import TaskViewModal, {ModalName as TaskViewModalName} from "../../../../components/modals-new/task-view-modal/TaskViewModal";
import {show} from "redux-modal";
import {taskPriorityValueFormatter} from "../../../../utils/taskUtils";
import TaskAddModal, {ModalName as TaskAddModalName} from "../../../../components/modals-new/task-add-modal/TaskAddModal";
import TaskListMobileRow from "./TaskListMobileRow";
import {isMobile} from "../../../../utils/MobileUtils";
import ButtonGroup from "../../../../components/basics/button/button-group/ButtonGroup";
import TaskViewModalDrawer from "../../../../components/modals-new/task-view-modal/TaskViewModalDrawer";
import TaskAddModalDrawer from "../../../../components/modals-new/task-add-modal/TaskAddModalDrawer";
import TaskFilter from "./filter/TaskFilter";
import sift from "sift";
import {getTaskDate, getTaskFilters, getTaskLoading, getTasks} from "../../../../selectors/taskSelectors";
import {changeDate, getTasksAPI, setSelectedTask} from "../../../../actions/taskActions";
import LoadingComponent from "../../../../components/loading/LoadingComponent";

function mapStateToProps(state) {
    return {
        farm: state.location.farm,
        tasks: getTasks(state),
        filters: getTaskFilters(state),
        loading: getTaskLoading(state),
        date: getTaskDate(state),
        employees: state.user.employees
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        ...bindActionCreators({show}, dispatch)
    }
}

class TaskList extends Component {

    state = {
        date: moment().format(moment.HTML5_FMT.DATE),
        showDrawer: false,
        showAddDrawer: false,
        showFilter: null
    }

    componentDidMount() {
        this.props.dispatch(getTasksAPI(+moment.utc(this.state.date)));
    }

    timeValueFormatter = value => value + "h";

    onRowClick = row => {
        this.props.dispatch(setSelectedTask(row));
        this.props.show(TaskViewModalName);
    }

    onChangeDate = value => {
        this.props.dispatch(changeDate(value));
    }

    onAddClick = () => {
        if (isMobile()) {
            this.props.dispatch(setSelectedTask(null));
            this.onMobileAddClick();
        } else {
            this.props.show(TaskAddModalName);
        }
    }

    onMobileAddClick = () => {
        this.setState({
            showAddDrawer: true
        })
    }

    onMobileClick = row => {
        this.props.dispatch(setSelectedTask(row));
        this.setState({
            showDrawer: true
        });
    }

    onHideMobileModal = () => {
        this.setState({
            showDrawer: false
        })
    }

    onHideMobileAdd = () => {
        this.setState({
            showAddDrawer: false
        })
    }

    onFilterButtonClick = (e) => {
        this.setState({
            showFilter: e
        })
    }

    onHideFilters = () => {
        this.setState({
            showFilter: null
        })
    }

    employeeFormatter = value => {
        const {employees} = this.props;
        let employee = employees.find(item => item.LocalUserID === value);
        if (employee) {
            return employee.userName;
        }
    }

    render() {
        const {showDrawer, showAddDrawer, showFilter} = this.state;
        const {t, tasks, filters, loading, date} = this.props;
        const headers = [
            {
                headerClassName: "index",
                itemClassName: "index",
                field: "Status",
                component: <IsDoneRenderer/>
            },
            {
                name: t("task"),
                field: "Title",
                headerClassName: "task-cell",
                itemClassName: "task-cell",
            },
            {
                name: t("eventGrid.operator"),
                field: "AssignTo",
                valueFormatter: this.employeeFormatter
            },
            {
                name: t("priority"),
                field: "Priority",
                valueFormatter: taskPriorityValueFormatter
            },
            {
                name: t("estimatedTime"),
                field: "EstimatedTime",
                valueFormatter: this.timeValueFormatter
            },
            {
                headerClassName: "index",
                itemClassName: "index",
                component: () => <i className="fas fa-chevron-right"/>
            }
        ];
        let mobile = isMobile();
        return (
            <Card className="task-list">
                <LoadingComponent isLoading={loading}/>
                <h4 className="align-items-baseline">
                    <i className="fas fa-tasks mr-1"/>
                    {t("tasksToDo")}
                </h4>
                <div className="task-list-controllers">
                    <Input type={"date"} value={date} onChange={this.onChangeDate}/>
                    <Button buttonStyle={"round"} icon={<i className="fas fa-filter"/>}
                            onClick={this.onFilterButtonClick}/>
                    <TaskFilter event={showFilter} onHide={this.onHideFilters}/>
                </div>
                <TableGrid data={tasks.filter(sift(filters))} headers={headers} showPagination={!mobile} smallPagination
                           onRowClick={this.onRowClick} paginationItems={5}
                           mobileRow={<TaskListMobileRow onMobileClick={this.onMobileClick}
                                                         employeeFormatter={this.employeeFormatter}/>}/>
                <ButtonGroup fixed={mobile} className="justify-content-end">
                    <Button icon={<i className="fas fa-plus"/>} buttonStyle={mobile ? "round" : "bordered"}
                            onClick={this.onAddClick} buttonColor={mobile ? "success" : ""}>
                        {t("addTask")}
                    </Button>
                </ButtonGroup>
                <TaskViewModal/>
                <TaskAddModal/>
                <TaskViewModalDrawer show={showDrawer} onHide={this.onHideMobileModal}
                                     onEditClick={this.onMobileAddClick}/>
                <TaskAddModalDrawer show={showAddDrawer} onHide={this.onHideMobileAdd}/>
            </Card>
        );
    }
}

export default compose(
    withTranslation(),
    connect(mapStateToProps, mapDispatchToProps)
)(TaskList);