import React from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import ReactDOM from "react-dom"

export default class ChildRenderer extends React.Component {

    constructor(props) {
        super(props);
        this.child = React.createRef();
    }

    getClassName() {
        const {className} = this.props;
        let cName = "child";
        if (className) cName += " " + className;
        return cName;
    }

    render() {
        const {text, isFullWidth, containerWidth} = this.props;
        let top = 0, left = 0, width = 0, height = 0;
        if (this.child.current && isFullWidth) {
            let rect = this.child.current.getBoundingClientRect();
            top = rect.top + window.scrollY;
            left = rect.left + 1;
            width = containerWidth - 1;
            height = rect.height;
        }

        return (
            <div className={this.getClassName()} ref={this.child}>
                {!isFullWidth && text}
                {
                    isFullWidth &&
                    ReactDOM.createPortal(
                        <div className="results-grid-full-width" style={{top, left, width, height}}>
                            {text}
                        </div>,
                        document.getElementById("root") || document.createElement("div"))
                }
            </div>
        );
    }
}

ChildRenderer = connect((state) => ({
}))(ChildRenderer);

ChildRenderer.propTypes = {
    text: PropTypes.node.isRequired,
    isFullWidth: PropTypes.bool,
    containerWidth: PropTypes.number,
    className: PropTypes.string
};