import React from "react";
import "./_results.scss"
import HeaderRenderer from "./results-renderers/HeaderRenderer";
import {getFarmStart} from "../../../utils/SettingsUtils";
import moment from "moment";
import {connect} from "react-redux";
import {prepareResultsGrid} from "../../../actions/gridActions";
import ChildRenderer from "./results-renderers/ChildRenderer";
import Input from "../../../components/basics/input/Input";
import {cloneDeep, isEmpty, isEqual} from "lodash"
import ButtonGroup from "../../../components/basics/button/button-group/ButtonGroup";
import Button from "../../../components/basics/button/Button";
import Setting from "../../../beans/Setting";
import {SettingTypes} from "../../../constans/settingTypes";
import {createSettingDynamoDB, updateSettingDynamoDB} from "../../../actions/settingsAction";
import LabeledSelect from "../../../components/basics/select/labeled-select/LabeledSelect";
import LoadingComponent from "../../../components/loading/LoadingComponent";
import NotFound from "../../../components/NotFound";
import {findProperUnitType} from "../../../utils/UnitUtils"
import animalsDB from "../../../database/animalsDB";
import {preEvents} from "../../../utils/AnimalDocumentsUtils";
import {withTranslation} from "react-i18next";

export class ResultsTab extends React.Component {

    constructor(props) {
        super(props);

        let weekArray = [];
        let fromTime = getFarmStart();
        let i = 0;
        if (moment(fromTime).year() === moment().year()) {
            i = fromTime.isoWeek() - 1;
        }
        // TODO poza pojedynczymi tygodniami bedzie podzial per grupa technologiczna (niekoniecznie co 3 tygodnie)
        for (i; i <= moment().weeksInYear() - 1; i++) {
            weekArray.push(i + 1);
        }
        this.state = {
            weeks: weekArray,
            planBefore: [],
            plan: [],
            loading: true,
            year: moment().year(),
            animalsMap: this.initializeAnimalsMap()
        };
        this.left = React.createRef();
        this.right = React.createRef();
        this.container = React.createRef();

        this.initializeAnimalsMap();
        this.getData(moment().year());
    }

    initializeAnimalsMap() {
        const {farm} = this.props;
        const animals = animalsDB.getAllSows(farm, true, true);
        const animalsMap = new Map();
        if (!isEmpty(animals)) {
            for (const animal of animals) {
                let R = preEvents(animal.events);
                let sortedEvents = !isEmpty(animal.events) ? animal.events.sort((a, b) => a.EvTime - b.EvTime) : [];
                animalsMap.set(animal.AnmID, {
                    AnimalKind: animal.AnimalKind,
                    CycleTable: R.cycleTable,
                    ResultTable: R.resultTable,
                    Events: sortedEvents,
                    Animal: animal
                });
            }
        }
        return animalsMap;
    }

    getData(year) {
        const {animalsMap} = this.state;
        let plan = [], before = [];
        let fromTime = getFarmStart();
        // todo - tak samo jak powyżej przekazany bedzie musial byc argument odnosnie grup technologicznych
        let weeks = 1;
        if (this.props.plan) {
            this.props.plan.SetData.Plans.map(p => {
                if (p.Year === year) plan = p.Data;
                if (p.Year === year - 1) before = p.Data
            });
        }
        this.props.dispatch(prepareResultsGrid(this.props.farm, cloneDeep(this.props.resultsGrid), plan, before, fromTime, weeks, year, animalsMap));
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if (this.props.loading !== nextProps.loading) {
            setTimeout(() => {
                this.setState({
                    loading: nextProps.loading
                })
            }, 100)
        }
        if (!isEqual(this.props.resultsGrid, nextProps.resultsGrid)) {
            let planBefore = nextProps.resultsGrid.map(val => !val.isFullWidth ? val.before || 0 : null);
            let plan = nextProps.resultsGrid.map(val => !val.isFullWidth ? val.plan || 0 : null);
            this.setState({
                planBefore,
                plan
            });
        }

        if (!isEqual(this.props.lang, nextProps.lang)) {
            this.getData(this.state.year);
        }
    }

    onChange(value, index) {
        let planBefore = this.state.planBefore.slice(0);
        planBefore[index] = +value;
        this.setState({
            planBefore
        })
    }

    onPlanChange(value, index) {
        let plan = this.state.plan.slice(0);
        plan[index] = +value;
        this.setState({
            plan
        })
    }

    onButtonClick = () => {
        const {plan} = this.props;
        if (!plan) {
            // stworzenie nowego
            let plan = Setting.createSetting(SettingTypes.YEARLY_PLANS, {
                Plans: [
                    {
                        Data: this.state.plan.filter(item => item !== null),
                        Year: this.state.year
                    },
                    {
                        Data: this.state.planBefore.filter(item => item !== null),
                        Year: this.state.year - 1
                    }
                ]
            });
            this.props.dispatch(createSettingDynamoDB(plan));
        } else {
            // update
            let copy = plan.clone();
            copy.setPlan(this.state.year, this.state.plan.filter(item => item !== null));
            copy.setPlan(this.state.year - 1, this.state.planBefore.filter(item => item !== null));
            this.props.dispatch(updateSettingDynamoDB(copy.prepareBeanToSave()));
        }
    };

    scrollLeft = () => {
        if (document) {
            let scrollPane = document.getElementsByClassName("results-grid-column main")[0];
            let weekSize = document.getElementsByClassName("header-child")[0].clientWidth - 8;
            scrollPane.scrollLeft = scrollPane.scrollLeft - weekSize;
        }
    };

    scrollRight = () => {
        if (document) {
            let scrollPane = document.getElementsByClassName("results-grid-column main")[0];
            let weekSize = document.getElementsByClassName("header-child")[0].clientWidth - 8;
            scrollPane.scrollLeft = scrollPane.scrollLeft + weekSize;
        }
    };

    onYearChange = value => {
        this.setState({
            year: value
        });
        this.getData(value);
    };

    getYears() {
        let fromTime = getFarmStart();
        let array = [];
        for (let i = moment(fromTime).year(); i <= moment().year(); i++) {
            array.push({
                name: i,
                value: i
            })
        }
        return array;
    }

    render() {
        const {resultsGrid, t} = this.props;
        const {weeks, loading, planBefore, plan, year} = this.state;
        let marginLeft = 0, width = 0, containerWidth = 0;
        if (this.container.current) {
            containerWidth = this.container.current.clientWidth;
            width = this.container.current.clientWidth;
        }
        if (this.left.current) {
            marginLeft = this.left.current.clientWidth;
            width -= this.left.current.clientWidth;
        }
        if (this.right.current) {
            width -= this.right.current.clientWidth;
        }

        let weightArray = [];
        for (let i = 0; i < resultsGrid.length; i++) {
            if (resultsGrid[i].valueType === "weight") {
                weightArray.push(resultsGrid[i]);
            }
        }


        let max = Math.max.apply(Math, weightArray.map(o => o.sum));
        return (
            <div>
                {
                    resultsGrid.length === 0 ? <div><LoadingComponent isLoading={loading}/> <NotFound/></div> :
                        <div>
                            <LoadingComponent isLoading={loading}/>
                            <LabeledSelect label={t("results.year")} options={this.getYears()}
                                           onChange={this.onYearChange} value={year} clearButton={false}/>
                            <div className="results" ref={this.container} id="results-grid">
                                <div className="results-grid-column left" ref={this.left}>
                                    <div className="results-grid">
                                        <HeaderRenderer title={t("designation")} className="first-header"/>
                                        <HeaderRenderer title={t("unit")}/>
                                        <HeaderRenderer title={t("results.plan")} columns={[year - 1, year]}/>
                                        {
                                            resultsGrid.map((data, index) =>
                                                <React.Fragment key={index}>
                                                    <ChildRenderer
                                                        text={t(`${data.name}`)}
                                                        isFullWidth={data.isFullWidth}
                                                        containerWidth={containerWidth}
                                                        className="results-name"
                                                    />
                                                    <ChildRenderer
                                                        text={t(`${data.unit}`)}
                                                        containerWidth={containerWidth}
                                                    />
                                                    <Input type="number" value={planBefore[index]}
                                                           onChange={val => this.onChange(val, index)}/>
                                                    <Input type="number" value={plan[index]}
                                                           onChange={val => this.onPlanChange(val, index)}/>
                                                </React.Fragment>
                                            )
                                        }
                                    </div>
                                </div>
                                <div className="results-grid-column main" style={{marginLeft, width}}>
                                    <div className="results-grid">
                                        <HeaderRenderer title={t("results.week")} columns={weeks}
                                                        spanClassName="fixed-header"/>
                                        {
                                            resultsGrid.map((data, index) => {
                                                return weeks.map((week, i) =>
                                                    <ChildRenderer text={data.isFullWidth ? "-" : data[`week${week}`]}
                                                                   key={`${index}_${i}`}
                                                                   unit={findProperUnitType(max)}
                                                                   valueType={data.valueType}
                                                                   className={data.revertClass ? data[`week${week}`] <= plan[index] ? "success" : "error" : data[`week${week}`] < plan[index] ? "error" : "success"}
                                                    />
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                                <div className="results-grid-column right" ref={this.right}>
                                    <div className="results-grid">
                                        <HeaderRenderer title={t("results.quarter")} columns={[1, 2, 3, 4]}/>
                                        <HeaderRenderer title={t("results.year")}/>
                                        <HeaderRenderer title={t("results.sum")}/>
                                        {
                                            resultsGrid.map((data, index) =>
                                                <React.Fragment key={index}>
                                                    <ChildRenderer unit={findProperUnitType(max)}
                                                                   text={data.isFullWidth ? "-" : data.quarter1}/>
                                                    <ChildRenderer unit={findProperUnitType(max)}
                                                                   valueType={data.valueType}
                                                                   text={data.isFullWidth ? "-" : data.quarter2}/>
                                                    <ChildRenderer unit={findProperUnitType(max)}
                                                                   valueType={data.valueType}
                                                                   text={data.isFullWidth ? "-" : data.quarter3}/>
                                                    <ChildRenderer unit={findProperUnitType(max)}
                                                                   valueType={data.valueType}
                                                                   text={data.isFullWidth ? "-" : data.quarter4}/>
                                                    <ChildRenderer unit={findProperUnitType(max)}
                                                                   valueType={data.valueType}
                                                                   text={data.isFullWidth ? "-" : data.year}/>
                                                    <ChildRenderer unit={findProperUnitType(max)}
                                                                   valueType={data.valueType}
                                                                   text={data.isFullWidth ? "-" : data.sum}/>
                                                </React.Fragment>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                            <ButtonGroup fixed renderInPortal>
                                <Button buttonColor="info" buttonStyle="round"
                                        icon={<i className="fas fa-chevron-left"/>}
                                        onClick={this.scrollLeft}/>
                                <Button buttonColor="info" buttonStyle="round"
                                        icon={<i className="fas fa-chevron-right"/>}
                                        onClick={this.scrollRight}/>
                                <Button buttonColor="success" buttonStyle="round" icon={<i className="fas fa-save"/>}
                                        onClick={this.onButtonClick}/>
                            </ButtonGroup>
                        </div>
                }
            </div>
        );
    }
}

ResultsTab = connect(state => ({
    farm: state.location.farm,
    resultsGrid: state.grid.resultsGridData,
    loading: state.grid.loadingResultsGrid,
    plan: state.settings.plan,
    lang: state.language.lang.lang,
}))(ResultsTab);

ResultsTab = withTranslation()(ResultsTab);

export default ResultsTab;
