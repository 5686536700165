import React, {Component} from 'react';
import {connect} from 'react-redux';
import Card from "../../../components/basics/card/Card";
import ListFullWidth from "../../../components/list-full-width/ListFullWidth";
import {getOnGoingAlarms} from "../../../IOT/device-functions/GatewayFunctions";
import {DevType} from "../../../constans/devices";
import {addAlarm, iotAlarmsReset, setFetching} from "../../../actions/iotAlarmsActions";
import {getAlarmsData, isFetched, isFetching} from "../../../selectors/iotAlarmsSelector";
import LoadingComponent from "../../../components/loading/LoadingComponent";
import ViewContainerModal from "../../../components/basics/view-containter-modal/ViewContainerModal";
import ModalHeader from "../../../components/modals-new/ModalHeader";
import ModalBody from "../../../components/modals-new/ModalBody";
import ModalFooter from "../../../components/modals-new/ModalFooter";
import {Modal} from "react-bootstrap";
import {isMobile} from "../../../utils/MobileUtils";
import _ from "lodash";
import AlarmDetailsBody from "../../../components/alarm-components/AlarmDetailsBody";
import {withTranslation} from "react-i18next";
import {isUsingFakeData} from '../../../utils/SettingsUtils';
import sinon from "sinon";
import {fakeGetData} from "../../../demo/alarms/fakeFunctions";
import i18next from "i18next";
import {makeGetDevicesByType} from "../../../selectors/deviceSelector";

const params = {DevType: DevType.GATEWAY, showExpired: false};

function makeMapStateToProps() {
    return function mapStateToProps(state) {
        const getDevicesByType = makeGetDevicesByType();
        return {
            data: getAlarmsData(state, params),
            gateways: getDevicesByType(state, params),
            fetching: isFetching(state, null),
            fetched: isFetched(state, null),
            dataLoaderActive: state.dataLoader.loading
        }
    };
}

const DataLimit = 500;
const TablePageSize = 10;
const Sizing = [3, 9];

class Alarms extends Component {

    constructor(props) {
        super(props);
        props.dispatch(iotAlarmsReset());
        this.state = {
            alarmData: null,
            show: false
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {gateways} = this.props;
        if (prevProps.gateways.length !== gateways.length) {
            this.getData(this.props);
        }
    }

    componentDidMount() {
        //fake data
        if (isUsingFakeData()) {
            sinon.restore();
            sinon.stub(Alarms.prototype, "getData").callsFake(fakeGetData)
        }
        this.getData(this.props);
        this.interval = setInterval(() => {
            this.getData(this.props)
        }, 2 * 60 * 1000)
    }

    componentWillUnmount() {
        if (isUsingFakeData()) {
            sinon.restore();
        }
        clearInterval(this.interval);
    }

    getData(props, {limit = DataLimit} = {}) {
        if (!isUsingFakeData()) {
            const {gateways, dispatch} = props;
            gateways.forEach(gw => {
                dispatch(setFetching(gw.GatewayID, true));
                getOnGoingAlarms(gw, {limit},
                    {
                        onSuccess: (data) => {
                            dispatch(addAlarm(gw.GatewayID, _.get(data, "CAnsw.alarms", {})));
                        },
                        onFailure: () => {
                            dispatch(setFetching(gw.GatewayID, false));
                        },
                        onSend: () => {
                            dispatch(setFetching(gw.GatewayID, true));
                        }
                    });
            })
        }
    }

    onExpand = (data) => {
        this.setState({
            alarmData: data,
            show: true
        })
    };

    onHide = () => {
        this.setState({
            show: false
        })
    };


    placeRenderer = (value) => {
        const {t} = this.props;
        switch (value.length) {
            case 0:
                return t("missingLocation");
            case 1:
                return <span title={value[0].join(" - ")}>{value[0].join(" - ")}</span>
            default:
                return <span
                    title={value.map(o => o.join(" - ")).join("\n")}>{i18next.t("farms.tabs.generalTab.manyLocationsX", {count: value.length})}</span>
        }
    };

    errorRenderer = (value, data) => {
        const {_passThrough: {other = []}} = data;
        return other.length > 1 ? i18next.t("farms.tabs.generalTab.manyAlertsX", {count: other.length}) : i18next.t(value.key, value.params);
    };

    render() {
        const {data, fetching, t, gateways, fetched} = this.props;
        const loading = fetching;
        const {show, alarmData} = this.state;
        const modalBody = <AlarmDetailsBody alarm={alarmData}/>;
        const mobile = isMobile();
        const listLoading = !data.length && loading;
        const iconLoading = !!data.length && loading;
        if (!gateways.length) {
            return null;
        }
        return (
            <Card className={!data.length ? "mh-15rem w-100 d-flex flex-column" : "w-100 d-flex flex-column"}>
                <h4 className={"align-items-baseline"}><i
                    className="fas fa-bell-exclamation mr-1"/>{t("alarms")} {iconLoading &&
                <i className={"fas fa-spin fa-circle-notch ml-1"}/>}</h4>
                <div className={"position-relative h-100"}>
                    {
                        !!gateways.length && <>
                            <LoadingComponent isLoading={listLoading} bgInherit={true} type={"component"}/>
                            <ListFullWidth loading={loading} mobile={mobile}
                                           onExpand={this.onExpand}
                                           data={data}
                                           sizing={Sizing}
                                           options={{
                                               subtitle: {
                                                   rtl: true,
                                                   renderer: this.placeRenderer
                                               },
                                               text: {
                                                   bold: true,
                                                   renderer: this.errorRenderer
                                               }
                                           }}
                                           pageSize={TablePageSize}
                                           maxPages={Math.ceil(data.length / TablePageSize)}/>
                        </>
                    }
                    {
                        !data.length && !loading &&
                        <p className={"d-flex align-items-center justify-content-center text-center opacity-75 h-100"}>{fetched ? t("farms.tabs.generalTab.noAlarmsFound") : t("farms.tabs.generalTab.errorLoadingData")}<i
                            className={`fas ml-1 fa-${fetched ? "smile success" : "exclamation-triangle warning"}`}/>
                        </p>
                    }
                </div>
                {
                    mobile &&
                    <ViewContainerModal name={t("farms.tabs.generalTab.alertDetails")} show={show} onHide={this.onHide}>
                        <Card>{modalBody}</Card>
                    </ViewContainerModal>
                }
                {
                    !mobile &&
                    <Modal onHide={this.onHide} show={show} size={"lg"}>
                        <ModalHeader
                            title={t("farms.tabs.generalTab.alertDetails")}
                            onCloseClick={this.onHide}/>
                        <ModalBody>
                            {modalBody}
                        </ModalBody>
                        <ModalFooter
                            hasConfirmButton={false}
                            confirmText={t("close")}
                            onCloseClick={this.onHide}
                        />
                    </Modal>
                }


            </Card>
        );
    }
}

Alarms = connect(
    makeMapStateToProps,
)(Alarms);

export default withTranslation()(Alarms);
