import React from "react";
import PropTypes from "prop-types"
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {show} from "redux-modal";
import withRoles from "../../../components/withRoles";
import {Roles} from "../../../constans/roles";
import NewIOT from "../../../IOT/NewIOT";
import {getDeviceChamberMap, groupDevicesByGatewayID} from "../../../utils/DevicesUtils";
import {DevType} from "../../../constans/devices";
import NoDataFoundLoading from "../../../components/no-data-found-farm-view/NoDataFoundLoading";
import ButtonGroup from "../../../components/basics/button/button-group/ButtonGroup";
import Menu from "../../../components/basics/menu/Menu";
import MenuItem from "../../../components/basics/menu/MenuItem";
import InfoBox from "../../../components/basics/info-box/InfoBox";
import Button from "../../../components/basics/button/Button";
import {withTranslation} from "react-i18next";
import _ from "lodash";
import ChainFeeding from "../../../components/devices-rows/chain-feeding/ChainFeeding";
import ChainFeedingConfigureModal, {ModalName as ChainFeedingConfigureModalName} from "../../../components/modals-new/chain-feeding-configure-modal/ChainFeedingConfigureModal"
import {startMotor, stopMotor} from '../../../IOT/device-functions/ChainFeedingFunctions';
import {isUsingFakeData} from "../../../utils/SettingsUtils";
import sinon from "sinon";
import {
    fakeCreateAndSendMessageObject,
    fakeStartSendingDeviceState,
    INTERVAL_TIME,
    refresh
} from "../../../demo/chain-feeding/fakeFunctions";

export class ChainFeedingTab extends React.Component {

    constructor(props) {
        super(props);
        this.devicePolling = [];
        this.state = {
            selected: [],
            event: null
        }
        //wersja demo
        const useFakeData = isUsingFakeData();
        if (useFakeData) {
            sinon.restore();
            sinon.stub(NewIOT, "startSendingDeviceState").callsFake(fakeStartSendingDeviceState);
            sinon.stub(NewIOT, "createAndSendMessageObject").callsFake(fakeCreateAndSendMessageObject);
            sinon.stub(NewIOT, "sendRequestForAggregatedData").callsFake(() => {
            });
            this.timer = setInterval(() => {
                refresh(this.props.chainFeeding)
            }, INTERVAL_TIME)
        }
    }

    onSelect = device => {
        const {selected} = this.state;
        let selectedCopy = selected.slice(0);
        const index = selectedCopy.findIndex(item => item.DevID === device.DevID);
        if (index === -1) {
            selectedCopy.push(device);
        } else {
            selectedCopy.splice(index, 1);
        }
        this.setState({
            selected: selectedCopy
        })
    };

    onMenuOpen = (event, device) => {
        if (_.get(device, "DevType") === DevType.CHAIN_FEEDING) {
            this.setState({
                selected: [device],
                event
            })
        } else {
            this.setState({
                event
            })
        }

    };

    componentDidMount() {
        this.startPolling();
    }

    componentWillUnmount() {
        //wylaczenie odpytywania
        this.clearPolling();
        clearInterval(this.timer);
        if (isUsingFakeData()) {
            sinon.restore();
        }
    }

    clearPolling = () => {
        NewIOT.removeFromInterval(this.devicePolling);
        this.devicePolling = [];
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {chainFeeding} = this.props;
        if (chainFeeding.length !== prevProps.chainFeeding.length) {
            this.startPolling();
        }
    }

    startPolling = () => {
        const {chainFeeding} = this.props;
        //wyczyszczneie jesli odpytuje cos
        this.clearPolling();
        //pobranie danych
        let devices = groupDevicesByGatewayID(chainFeeding);
        if (devices) {
            for (let d of devices.values()) {
                if (d[DevType.CHAIN_FEEDING].length) {
                    this.devicePolling.push(NewIOT.startSendingDeviceState(d[DevType.CHAIN_FEEDING]));
                    NewIOT.sendRequestForAggregatedData(d[DevType.CHAIN_FEEDING])
                }
            }
        }
    };

    checkSelected = (selected, DevID) => {
        return !!~selected.findIndex(o => o.DevID === DevID);
    };

    openModal = (modalName, extraProps = {}) => {
        const {show} = this.props;
        show(modalName, {devices: this.state.selected, ...extraProps});
    };

    onConfigurationModalOpen = () => {
        this.openModal(ChainFeedingConfigureModalName);
    };

    onStartChainModalOpen = () => {
        const {show, t} = this.props;
        const {selected} = this.state;
        show("confirm-modal", {
            title: t("deviceRows.chainFeeding.chainFeedingTab.startMotor"),
            text: t("deviceRows.chainFeeding.chainFeedingTab.startMotorText"),
            confirmText: t("start"),
            onConfirmed: (props) => {
                selected.forEach(device => {
                    startMotor(device);
                })
                props.handleHide();
            }
        })
    }

    onStopChainModalOpen = () => {
        const {show, t} = this.props;
        const {selected} = this.state;
        show("confirm-modal", {
            title: t("deviceRows.chainFeeding.chainFeedingTab.stopMotor"),
            text: t("deviceRows.chainFeeding.chainFeedingTab.stopMotorText"),
            confirmText: t("stop"),
            onConfirmed: (props) => {
                selected.forEach(device => {
                    stopMotor(device);
                })
                props.handleHide();
            }
        })
    }

    render() {
        const {chainFeeding, t, loading, buildings} = this.props;
        const {event, selected} = this.state;
        const map = getDeviceChamberMap(buildings, chainFeeding);
        const menuOptions = [
            {
                icon: "fas fa-cog fa-fw",
                action: this.onConfigurationModalOpen,
                name: t("configuarion")
            },
            {
                icon: "fas fa-play fa-fw",
                action: this.onStartChainModalOpen,
                name: t("deviceRows.chainFeeding.chainFeedingTab.startMotor")
            },
            {
                icon: "fas fa-stop fa-fw",
                action: this.onStopChainModalOpen,
                name: t("deviceRows.chainFeeding.chainFeedingTab.stopMotor")
            }
        ]

        if (!chainFeeding.length) {
            return (
                <NoDataFoundLoading isLoading={loading} dataLength={chainFeeding.length}
                                    noDataText={t("deviceRows.chainFeeding.chainFeedingTab.chainFeedingNotFound")}/>
            )
        }
        return (
            <>
                {
                    chainFeeding.map((device, index) => <ChainFeeding
                        {...(map.get(device.DevID) || {})}
                        device={device}
                        key={index}
                        selected={this.checkSelected(selected, device.DevID)}
                        onSelect={this.onSelect}
                        onMenuOpen={this.onMenuOpen}
                    />)
                }
                <ButtonGroup fixed>
                    <Menu event={event}>
                        {
                            menuOptions.map((menu, index) => (!menu.hide ?
                                    <MenuItem key={index} icon={<i className={menu.icon}/>}
                                              onClick={menu.action}>
                                        {menu.name}
                                    </MenuItem> : null
                            ))
                        }

                    </Menu>
                    <InfoBox boxColor={"info"}>
                        {t("deviceRows.chainFeeding.chainFeedingTab.selected", {count: selected.length})}
                    </InfoBox>
                    <Button buttonStyle={"round"} buttonColor={"info"}
                            type={"button"}
                            icon={<i className="fas fa-ellipsis-v"/>} disabled={selected.length === 0}
                            onClick={this.onMenuOpen}/>
                </ButtonGroup>
                <ChainFeedingConfigureModal/>
            </>
        );
    }

}

ChainFeedingTab.propTypes = {
    chainFeeding: PropTypes.shape({
        DevID: PropTypes.object.isRequired,
        GatewayID: PropTypes.object.isRequired
    }).isRequired
};

ChainFeedingTab = connect(
    null,
    dispatch => bindActionCreators({show}, dispatch)
)(ChainFeedingTab);
export let _ChainFeedingTab = connect(state => ({
    loading: state.dataLoader.loading,
    buildings: state.farms.buildings,
}))(ChainFeedingTab);

_ChainFeedingTab = withTranslation()(_ChainFeedingTab);

export default withRoles({roles: [Roles._DEVICE_CHAIN], showInfo: true})(_ChainFeedingTab);
