import React from "react";
import Card from "../../../components/basics/card/Card";
import ResultsTab from "./ResultsTab";
import ResultsChart from "./ResultsChart";
import ReactGA from "react-ga";
import withRoles from "../../../components/withRoles";
import {Roles} from "../../../constans/roles";
import TextSwitch from "../../../components/basics/text-switch/TextSwitch";
import {withTranslation} from "react-i18next";

export class Results extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            viewType: 1
        };
        ReactGA.modalview("farm/results");
    };

    onSwitchView = (value) => {
        this.setState({
            viewType: value
        });
    };

    render() {
        let component;
        const {viewType} = this.state;
        const {t} = this.props;
        const switchOptions = [
            {
                name: t("table"),
                value: 1
            },
            {
                name: t("chart"),
                value: 2
            }
        ];
        viewType === 1 ? component = <ResultsTab/> : component = <ResultsChart/>;

        return (
            <Card>
                <TextSwitch onChange={this.onSwitchView} value={viewType} options={switchOptions}/>
                {component}
            </Card>
        )
    }
}

Results = withTranslation()(Results);

export default withRoles({roles: [Roles._BREEDING], showInfo: true})(Results);

