import React from "react";
import PropTypes from "prop-types"

export default class HeaderRenderer extends React.Component {

    getClassName() {
        const {columns, className} = this.props;
        let cName = "header";
        if (columns) cName += " has-children";
        if (className) cName += " " + className;
        return cName;
    }

    render() {
        const {title, columns, spanClassName} = this.props;
        return (
            <>
                <div className={this.getClassName()} style={columns ? {gridColumn: `span ${columns.length}`} : {}}>
                    <span className={spanClassName}>{title}</span>
                </div>
                {
                    columns && columns.map((col, index) =>
                        <div className="header-child" key={index}>
                            {col}
                        </div>
                    )
                }
            </>
        );
    }
}

HeaderRenderer.propTypes = {
    title: PropTypes.string.isRequired,
    columns: PropTypes.arrayOf(PropTypes.node),
    className: PropTypes.string,
    spanClassName: PropTypes.string
};