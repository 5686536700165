import React, {Component} from 'react';
import {connect} from "react-redux";
import Alarms from "./Alarms";
import Col from "react-bootstrap/Col";
import {Row} from "react-bootstrap";
import TaskList from "./tasks/TaskList";
import {isMobile} from "../../../utils/MobileUtils";

class GeneralTab extends Component {


    render() {
        return (
            <div>
                <Row>
                    <Col xs={12} sm={12} md={12} lg={12} xl={7}>
                        <TaskList/>
                    </Col>
                    {
                        !isMobile() &&
                        <Col xs={12} sm={12} md={12} lg={12} xl={5}>
                            <Alarms/>
                        </Col>
                    }
                </Row>
            </div>
        );
    }
}

GeneralTab.propTypes = {};
GeneralTab = connect()(GeneralTab);
export default GeneralTab;
