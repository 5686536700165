import React from "react";
import DispenserChamber from "../../../components/devices-rows/dispenser/DispenserChamber";
import {connect} from "react-redux";
import {get, isEqual} from "lodash";
import DispenserButtons from "../../../components/devices-rows/dispenser/advanced/DispenserButtons";
import {isMobile} from "../../../utils/MobileUtils";
import ReactGA from "react-ga";
import {getFeedingDataGroupedByChamberIDs} from "../../../utils/FeedingUtils";
import {withCookies} from "react-cookie";
import sinon from "sinon";
import NewIOT from "../../../IOT/NewIOT";
import {
    fakeCreateAndSendMessageObject,
    fakeStartSendingFeedingState,
    refresh,
    startSendingFeedingStateRFID
} from "../../../demo/feeding/fakeFunctions";
import {isUsingFakeData} from "../../../utils/SettingsUtils";
import withRoles from "../../../components/withRoles";
import {clearFeeding} from "../../../actions/feedingActions";
import {LicPackageKeys, LicPackageLevel, Roles} from "../../../constans/roles";
import TextSwitch from "../../../components/basics/text-switch/TextSwitch";
import DispenserButtonsSimple from "../../../components/devices-rows/dispenser/simple/DispenserButtonsSimple";
import {Col, Row} from "react-bootstrap";
import moment from "moment";
import NoDataFoundLoading from "../../../components/no-data-found-farm-view/NoDataFoundLoading";
import {getLicenseByName} from "../../../selectors/roleSelector";
import {getFeedingCurves, getFeedingForages, getFeedingSchedules} from "../../../selectors/feedingSelector";
import FeedingExtraFeedingModal from "../../../components/modals-new/feeding-extra-feeding/FeedingExtraFeedingModal";
import FeedingManageFeedingModal from "../../../components/modals-new/feeding-manage-feeding/FeedingManageFeedingModal";
import FeedingSetAlarmModal from "../../../components/modals-new/feeding-set-alarm/FeedingSetAlarmModal";
import FeedingSetTypeEventModal from "../../../components/modals-new/feeding-set-type-event/FeedingSetTypeEventModal";
import FeedingSkipDosesModal from "../../../components/modals-new/feeding-skip-doses/FeedingSkipDosesModal";
import FeedingSowInfo from "../../../components/modals-new/feeding-sow-info/FeedingSowInfo";
import FeedingConditionModal from "../../../components/modals-new/feeding-condition-modal/FeedingConditionModal";
import AssignAnimalToStandingModal
    from "../../../components/modals-new/assign-animal-to-standing-modal/AssignAnimalToStandingModal";
import FeedingReportInseminationModal
    from "../../../components/modals-new/feeding-report-insemination/FeedingReportInseminationModal";
import FeedingReportParturitionModal
    from "../../../components/modals-new/feeding-report-parturition/FeedingReportParturitionModal";
import FeedingEmptyForageModal from "../../../components/modals-new/feeding-empty-forage/FeedingEmptyForageModal";
import FeedingHistoryModal from "../../../components/modals-new/feeding-history/FeedingHistoryModal";
import {withTranslation} from "react-i18next";
import FeedingExtraWaterModal from "../../../components/modals-new/feeding-extra-water-modal/FeedingExtraWaterModal";

const ViewType = {
    ADVANCED: 0,
    SIMPLE: 1
};

export class DispenserTab extends React.Component {

    constructor(props) {
        super(props);
        const {cookies, license} = this.props;
        const data = getFeedingDataGroupedByChamberIDs(this.props.buildings);
        this.state = {
            data: data,
            event: null,
            viewType: license === LicPackageLevel.BASIC ? ViewType.SIMPLE : +get(cookies.get("dispenser-tab"), "viewType", ViewType.ADVANCED)

        };
        this.props.dispatch(clearFeeding());
        if (isUsingFakeData()) {
            this.timer = setInterval(() => {
                refresh()
            }, 30 * 1000);
            sinon.restore();
            sinon.stub(NewIOT, "startSendingFeedingState").callsFake(fakeStartSendingFeedingState);
            sinon.stub(NewIOT, "startSendingFeedingStateRFID").callsFake(startSendingFeedingStateRFID);
            sinon.stub(NewIOT, "createAndSendMessageObject").callsFake(fakeCreateAndSendMessageObject);
        }
        ReactGA.modalview("farm/feeding");
        // _FeedingManageFeedingSubmit.__set__("submit",fakeSubmitManageFeedingModal);
        // sinon.stub(_FeedingManageFeedingSubmit, "submit").callsFake(fakeSubmitManageFeedingModal);
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        const {buildings, animalsTime, loading} = this.props;
        if ((animalsTime !== nextProps.animalsTime) || !isEqual(buildings, nextProps.buildings) || (loading !== nextProps.loading)) {
            this.setState({
                data: getFeedingDataGroupedByChamberIDs(nextProps.buildings)
            })
        }
    }

    componentWillUnmount() {
        clearInterval(this.timer);
        sinon.restore();
        const {cookies, dispatch} = this.props;
        cookies.set("dispenser-tab", {viewType: this.state.viewType}, {
            path: "/",
            expires: moment().add(1, "year").toDate()
        });
        dispatch(clearFeeding());
    }

    handleChangeViewType = newValue => {
        console.log("daadasd", newValue);
        this.setState({
            viewType: newValue
        })
    };

    handleButtonOpen = e => {
        this.setState({
            event: e
        })
    };

    getSettingsMap = () => {
        const {feedingCurves, feedingForages, feedingSchedules} = this.props;
        const curveMap = feedingCurves.length ? new Map([...feedingCurves.map(o => ([o.SetID, o]))]) : new Map();
        const forageMap = feedingForages.length ? new Map([...feedingForages.map(o => ([o.SetID, o]))]) : new Map();
        const scheduleMap = feedingSchedules.length ? new Map([...feedingSchedules.map(o => ([o.SetID, o]))]) : new Map();
        return {
            curveMap,
            forageMap,
            scheduleMap
        }
    };

    render() {
        const {data, event, viewType} = this.state;
        const {t, loading, license, selectedChamber} = this.props;
        const chambers = data.map(o => o.chamber);
        const chamber = selectedChamber ? chambers.find(ch => ch.CID === selectedChamber) : null;
        const showSimpleButtons = !!chamber && !!chamber.IndividualFeeding && !!viewType;
        const showAdvancedButtons = !!chamber && (!viewType || !chamber.IndividualFeeding);
        if (!data.length) {
            return (
                <NoDataFoundLoading isLoading={loading} dataLength={data.length}
                                    noDataText={t("deviceRows.feeding.dispenserTab.noChambers")}/>
            )
        }
        const map = this.getSettingsMap();
        const mobile = isMobile();
        const options = [
            {
                value: ViewType.SIMPLE,
                name: t("deviceRows.feeding.dispenserTab.simple")
            },
            {
                value: ViewType.ADVANCED,
                name: t("advanced")
            }
        ];
        return (
            <>
                {
                    (license === LicPackageLevel.EXTENDED) &&
                    <Col xs={12}>
                        <Row className={"mb-4 justify-content-center"}>
                            <Col xs={12} lg={6}>
                                <label className={"text-center"}>{t("deviceRows.feeding.dispenserTab.view")}</label>
                                <TextSwitch onChange={this.handleChangeViewType} value={viewType} options={options}/>
                            </Col>
                        </Row>
                    </Col>
                }
                {
                    data.map((d, index) => (
                        <DispenserChamber viewType={viewType} {...d} {...map} mobile={mobile} key={d.chamber.CID}
                                          index={index}
                                          setEvent={mobile ? undefined : this.handleButtonOpen}/>
                    ))
                }
                <DispenserButtons chambers={chambers}
                                  isActive={showAdvancedButtons} event={event}
                                  onClick={this.handleButtonOpen}/>
                <DispenserButtonsSimple chambers={chambers} isActive={showSimpleButtons}/>
                <FeedingExtraFeedingModal chambers={chambers}/>
                <FeedingManageFeedingModal/>
                <FeedingSetAlarmModal/>
                <FeedingSetTypeEventModal/>
                <FeedingSkipDosesModal/>
                <FeedingSowInfo/>
                <FeedingConditionModal/>
                <AssignAnimalToStandingModal/>
                <FeedingReportInseminationModal/>
                <FeedingReportParturitionModal/>
                <FeedingEmptyForageModal/>
                <FeedingHistoryModal/>
                <FeedingExtraWaterModal/>
            </>
        );
    }

}

const mapStateToProps = (state) => ({
    buildings: state.farms.buildings,
    selectedChamber: state.feeding.selectedChamber,
    feedingCurves: getFeedingCurves(state),
    feedingSchedules: getFeedingSchedules(state),
    feedingForages: getFeedingForages(state),
    loading: state.dataLoader.loading,
    license: getLicenseByName(state, {licenseName: LicPackageKeys.DISPENSER}),
    //to tylko potrzebne do zmiany zwierzat jako trigger
    animalsTime: state.animals.time
});

DispenserTab.defaultProps = {
    disableSwipe: true
};
export let _DispenserTab = connect(mapStateToProps)(DispenserTab);

_DispenserTab = withTranslation()(_DispenserTab);

export default withCookies(withRoles({roles: [Roles._DEVICE_FEEDING], showInfo: true})(_DispenserTab));
