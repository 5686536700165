import React from "react";
import withRoles from "../../../components/withRoles";
import {Roles} from "../../../constans/roles";
import PropTypes from "prop-types"
import {Level} from "../../../constans/levelTypes";
import {connect} from "react-redux";
import Card from "../../../components/basics/card/Card";
import {get, isEqual} from "lodash";
import animalsDB from "../../../database/animalsDB";
import ReactGA from "react-ga";
import groupsDB from "../../../database/groupsDB";
import technologyGroupsDB from "../../../database/technologyGroupsDB";
import {inventoryInit} from "../../../actions/inventoryActions";
import {CellMeasurer, CellMeasurerCache, WindowScroller} from "react-virtualized";
import {List as VirtualizedList} from "react-virtualized/dist/commonjs/List";
import getInventorySorted from "../../../selectors/inventorySelector";
import InventoryItem from "../../../components/inventory-view-components/InventoryItem";
import InventoryActions from "../../../components/inventory-view-components/InventoryActions";
import List from "../../../components/basics/list/List";
import {withRouter} from "react-router-dom";
import {changeSelectedItem, resetSelectedItem} from "../../../actions/animalDocumentsAction";
import queryString from "query-string";
import buildingsDB from "../../../database/buildingsDB";
import Animal from "../../../beans/Animal";
import Group from "../../../beans/Group";
import Document from "../../../components/animal-documents/Document";
import {Row} from "react-bootstrap";
import {getRemToPx, scrollToTop} from "../../../utils/DOMUtils";
import InfoBox from "../../../components/basics/info-box/InfoBox";
import InventoryTotal from "../../../components/inventory-view-components/InventoryTotal";
import NoDataFoundLoading from "../../../components/no-data-found-farm-view/NoDataFoundLoading";
import {withTranslation} from "react-i18next";
import TechnologyGroup from "../../../beans/TechnologyGroup";
import i18next from "i18next";

const Info = {
    NOT_CHANGED: null,
    OK: 0,
    RFID_FOUND_MORE_THAN_1: 1,
    ANIMAL_NOT_FOUND: 2,
    GROUP_NOT_FOUND: 3
};

export class Inventory extends React.Component {


    constructor(props) {
        super(props);
        // this.clearCache = _.debounce(this.clearCache.bind(this), 100);
        this.cache = new CellMeasurerCache({
            defaultHeight: getRemToPx(7),
            fixedWidth: true,
            keyMapper: (rowIndex) => {
                const data = this.props.data[rowIndex] || {};
                const len = get(data, "animals.length", 0);
                return `${data.id}${len ? "-" + len : ""}`;
            }
        });
        this.state = {
            status: Info.OK,
            loading: true,
            animalKind: i18next.t("animalKind", {returnObjects: true})
        };
        this.props.dispatch(resetSelectedItem());
        ReactGA.modalview("farm/inventory");
    }

    componentWillUnmount() {
        this.props.dispatch(resetSelectedItem());
    }

    clearStatus = () => {
        this.setState({
            status: Info.OK
        })
    };

    getAnimalByQueryString(props) {
        const {selectedItem, dispatch} = props;
        let status = Info.NOT_CHANGED;
        let extraParams = {};
        let queryParams = queryString.parse(props.location.search || "") || {};
        if (queryParams.documentsAnimal || queryParams.documentsGroup || queryParams.documentsRFID || queryParams.documentsTechnoGroup) {
            //pobranie po group id
            if (queryParams.documentsGroup) {
                const group = groupsDB.findGroupByID(queryParams.documentsGroup /*, props.farm*/);
                if (group instanceof Group) {
                    dispatch(changeSelectedItem(group));
                    return {status: Info.OK, extraParams: {}};
                } else {
                    status = Info.GROUP_NOT_FOUND;
                }
            }
            //pobranie po grupie tech
            if (queryParams.documentsTechnoGroup) {
                const group = technologyGroupsDB.getTechnoGroupByTGID(queryParams.documentsTechnoGroup /*, props.farm*/);
                if (group instanceof TechnologyGroup) {
                    dispatch(changeSelectedItem(group));
                    return {status: Info.OK, extraParams: {}};
                } else {
                    status = Info.GROUP_NOT_FOUND;
                }
            }
            //pobranie po animal id
            if (queryParams.documentsAnimal) {
                const animal = animalsDB.getAnimalById(queryParams.documentsAnimal /*, props.farm*/);
                if (animal instanceof Animal) {
                    this.props.dispatch(changeSelectedItem(animal));
                    return {status: Info.OK, extraParams: {}};
                } else {
                    status = Info.ANIMAL_NOT_FOUND;
                }
            }

            //pobranie po RFID zwierzecia albo dokumentu
            if (queryParams.documentsRFID) {
                let animals = animalsDB.getAnimalsByRfid(queryParams.documentsRFID, props.farm).filter(a => a instanceof Animal);
                if (animals.length === 0) {
                    status = Info.ANIMAL_NOT_FOUND;
                    try {
                        const stand = buildingsDB.getBoxBIDByRfid(queryParams.documentsRFID);
                        if (stand) {
                            animals = animalsDB.getAllAnimalsForLocation(stand, Level.BOX, {joinEvents: false}).filter(a => a instanceof Animal);
                        }
                    } catch (e) {

                    }
                }
                if (animals.length === 1) {
                    this.props.dispatch(changeSelectedItem(animals[0]));
                    return {status: Info.OK, extraParams: {}};
                } else if (animals.length > 1) {
                    status = Info.RFID_FOUND_MORE_THAN_1;
                    extraParams = {"search": `"RFID":"${queryParams.documentsRFID}"`}
                }
            }
            this.handleRowClick(null, {extraData: extraParams});
        }
        if (selectedItem) {
            this.props.dispatch(resetSelectedItem());
        }
        return {status, extraParams};
    }

    getAnimalKindMap = (props) => {
        const map = new Map();
        const {t} = props;
        const {animalKind} = this.state;
        if (Array.isArray(animalKind)) {
            animalKind.forEach((name, i) => {
                map.set(i, name);
            });
            map.set("group", t("group"));
            map.set("techno", t("farms.tabs.inventoryTab.technoGroup"));
        }
        return map;
    };

    componentDidMount() {
        this.initializeAnimals(this.props);
        const newStatus = this.getAnimalByQueryString(this.props);
        if (newStatus.status !== null) {
            this.setState({
                status: newStatus.status
            })
        }
        this.setState({
            animalKind: i18next.t("animalKind", {returnObjects: true})
        })
    }

    initializeAnimals = (props) => {
        const {farm, dispatch} = props;
        const animals = animalsDB.getAllAnimals(farm, undefined, true, false);
        const groups = groupsDB.getAllGroups(farm);
        const technoGroups = technologyGroupsDB.getTechnologyGroups(farm);
        dispatch(inventoryInit(animals, groups, technoGroups, this.getAnimalKindMap(props)));
    };

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if (!isEqual(nextProps.animalsTime, this.props.animalsTime) || (!isEqual(nextProps.technoTime, this.props.technoTime)) || !isEqual(nextProps.loading, this.props.loading)) {
            this.initializeAnimals(nextProps);
        }
        if (!isEqual(nextProps.location.search, this.props.location.search)) {
            const newStatus = this.getAnimalByQueryString(nextProps);
            console.log(newStatus, "new status");
            if (newStatus.status !== null) {
                this.setState({
                    status: newStatus.status
                })
            }
        }
    }

    rowRenderer = ({index, style, key, parent}) => {
        const {data} = this.props;
        const item = data[index] || {};
        // const len = _.get(item, "animals.length", 0);
        // const key = `${item.id}${len ? "-" + len : ""}`;
        return (
            <CellMeasurer
                cache={this.cache}
                columnIndex={0}
                key={key}
                parent={parent}
                rowIndex={index}
            >
                {({measure}) => (
                    <InventoryItem style={style} measure={measure} handleRowClick={this.handleRowClick} index={index}
                                   data={item}/>
                )}
            </CellMeasurer>
        );
    };

    handleRowClick = (data, {extraData = {}} = {}) => {
        const {location: {pathname, search}, history} = this.props;
        let searchParams = new URLSearchParams(search);
        searchParams.delete("documentsGroup");
        searchParams.delete("documentsAnimal");
        searchParams.delete("documentsRFID");
        searchParams.delete("documentsTechnoGroup");
        if (data) {
            searchParams.set(data.isGroup ? (data.isTechnoGroup ? "documentsTechnoGroup" : "documentsGroup") : "documentsAnimal", data.id);
        }
        for (let [key, value] of Object.entries(extraData)) {
            searchParams.set(key, `${value}`);
        }
        if (Object.keys(extraData).length) {
            searchParams.delete("location");
        }
        history.replace({
            pathname: pathname,
            search: searchParams.toString()
        });
    };


    handleHide = () => {
        this.handleRowClick(null);
    };

    getItemsMap = () => {
        const {selectedItem, data} = this.props;
        const ids = [];
        data.forEach(item => {
            ids.push({
                id: item.id,
                isGroup: !!item.isGroup,
                isTechnoGroup: !!item.isTechnoGroup
            });
            get(item, "animals", []).forEach(animal => {
                ids.push({
                    id: animal.id
                })
            })

        });
        return {
            ids,
            currentIndex: ids.findIndex(o => selectedItem && ([selectedItem.AnmID, selectedItem.AnmGrp, selectedItem.TGID].includes(o.id)))
        }
    };

    handlePrev = (updatePig = false) => {
        const {ids, currentIndex} = this.getItemsMap();
        if (~currentIndex && (currentIndex - 1) >= 0 && !!(ids[currentIndex - 1])) {
            if (updatePig) {
                this.handleRowClick(ids[currentIndex - 1])
            }
            return true;
        }
        return false;

    };

    handleNext = (updatePig = false) => {
        const {ids, currentIndex} = this.getItemsMap();
        console.log(ids, currentIndex);
        if (~currentIndex && (currentIndex + 1) <= ids.length && !!(ids[currentIndex + 1])) {
            if (updatePig) {
                this.handleRowClick(ids[currentIndex + 1])
            }
            return true;
        }
        return false;
    };

    clearCache = () => {
        console.log("clear cache");
        this.cache.clearAll();
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.data.length !== this.props.data.length) {
            scrollToTop();
        }
        // TODO - wykomentowuje bo zbyt duzo razy jest wolane. Jesli potrzebne prosze poprawic.
        // if (prevProps !== this.props) {
        //     this.setState({
        //         animalKind: i18next.t("animalKind", {returnObjects: true})
        //     })
        // }
    }

    render() {
        const {loading, data, inventorySize, t, showCollapsed} = this.props;
        const {status} = this.state;
        const isLoading = loading && !data.length;
        if (isLoading) {
            return (
                <NoDataFoundLoading isLoading={isLoading} dataLength={data.length}/>
            )
        }
        return (
            <>
                <Card>
                    <InventoryTotal/>
                </Card>
                <Card className={"mh-15rem overflow-visible"}>
                    {
                        !!status &&
                        <InfoBox className={"d-flex justify-content-between align-items-baseline"} boxColor={"warning"}>
                            {t(`farms.tabs.inventoryTab.errorTypes.${status}`)}
                            <i className={"fa fa-fw fa-times pointer"} onClick={this.clearStatus}/>
                        </InfoBox>
                    }
                    <Row className={"background-inherit"}>
                        <InventoryActions/>
                        <List useFlexLayout={false}>
                            <WindowScroller onResize={this.clearCache}>
                                {
                                    ({height, isScrolling, scrollTop, onChildScroll}) =>
                                        <VirtualizedList
                                            rowCount={data.length}
                                            width={window.innerWidth} // maxwidth
                                            rowRenderer={this.rowRenderer}
                                            scrollTop={scrollTop}
                                            autoHeight
                                            height={height}
                                            data={data}
                                            deferredMeasurementCache={this.cache}
                                            rowHeight={this.cache.rowHeight}
                                            showCollapsed={showCollapsed}
                                            isScrolling={isScrolling}
                                            onScroll={onChildScroll}
                                            overscanRowCount={3}
                                            className={"w-100 overflow-auto"} //width 100%
                                            noRowsRenderer={() => <div className={"placeholder"}>
                                                {
                                                    !data.length && !!inventorySize && t("farms.tabs.inventoryTab.noAnimalsWithConditions")
                                                }
                                                {
                                                    !data.length && !inventorySize && t("farms.tabs.inventoryTab.noAnimals")
                                                }
                                            </div>}

                                        />
                                }
                            </WindowScroller>
                        </List>
                        <Document handleNext={this.handleNext} handlePrev={this.handlePrev}
                                  handleHide={this.handleHide}/>
                    </Row>
                </Card>
            </>
        );
    }

}

Inventory.propTypes = {
    loc: PropTypes.object.isRequired,
    level: PropTypes.oneOf([Level.FARM, Level.BUILDING, Level.SECTOR, Level.CHAMBER, Level.BOX]).isRequired
};

export let _Inventory = connect(state => ({
    data: getInventorySorted(state),
    animalsTime: state.animals.time,
    technoTime: state.technologyGroup.time,
    farm: state.location.farm,
    loading: state.dataLoader.loading,
    inventorySize: state.inventory.size,
    showCollapsed: state.inventory.showCollapsed,
    selectedItem: state.animalDocuments.selectedItem,

}))(Inventory);

Inventory = withRoles({roles: [Roles._BREEDING], showInfo: true})(_Inventory);
Inventory = withTranslation()(Inventory);
export default withRouter(Inventory);
