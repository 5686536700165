import React from "react";
import Header from "../../components/basics/header/Header";
import {connect} from "react-redux";
import NotFound from "../../components/NotFound";
import ViewContainer from "../../components/basics/view-container/ViewContainer";
import SeparationCageTab from "./tabs/SeparationCageTab";
import {DevType} from "../../constans/devices";
import ClimateWSTTab from "./tabs/ClimateWSTTab";
import DispenserTab from "./tabs/DispenserTab";
import Inventory from "./tabs/Inventory";
import {Level} from "../../constans/levelTypes";
import {get, isEqual} from "lodash"
import SiloTab from "./tabs/SiloTab";
import {getFlatDevices} from "../../utils/DevicesUtils";
import Results from "../../views/new-farm-view/tabs/Results";
import sinon from "sinon";
import GeneralTab from "./tabs/GeneralTab";
import MapTab from "./tabs/MapTab";
import WaterTab from "./tabs/WaterTab";
import ElectricityTab from "./tabs/ElectricityTab";
import {withTranslation} from "react-i18next";
import ChainFeedingTab from "./tabs/ChainFeedingTab";
import PigletScaleTab from "./tabs/PigletScaleTab";
import {LicPackageKeys, LicPackageLevel} from "../../constans/roles";
import {getLicensePackage} from "../../selectors/roleSelector";
import {hasMinPackageLevel} from "../../utils/NewRolesUtils";
import i18next from "i18next";
import {isMobile} from "../../utils/MobileUtils";

@connect(state => ({
    user: state.user.user,
    farms: state.farms.farms,
    farm: state.location.farm,
    devices: state.farmDevices.devices,
    blockSwipeFeeding: !!state.feeding.feeding.size,
    dataLoaderLoading: state.dataLoader.loading,
    lang: state.language.lang.lang,
    licPackages: getLicensePackage(state)
}))
class FarmView extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            tab: 0,
            ...FarmView.getData(props)
        };
        if (!this.props.match.params.viewType) {
            this.onTabChange(0);
        }
    }



    static getData(props) {
        const TabNames = [
            {
                key: "general",
                namePath: i18next.t("general"),
                packageKeys: [LicPackageKeys.ALARM],
                packageLevels: [LicPackageLevel.BASIC],
            },
            {
                key: "inventory",
                namePath: i18next.t("inventory"),
                packageKeys: [LicPackageKeys.SOW, LicPackageKeys.PORKER],
                packageLevels: [LicPackageLevel.BASIC, LicPackageLevel.BASIC],
            },
            {
                key: "feeding",
                namePath: i18next.t("feeding"),
                packageKeys: [LicPackageKeys.DISPENSER, LicPackageKeys.DISPENSER_5G],
                packageLevels: [LicPackageLevel.BASIC, LicPackageLevel.BASIC],
            },
            {
                key: "climate",
                namePath: i18next.t("climate"),
                packageKeys: [LicPackageKeys.CLIMATE],
                packageLevels: [LicPackageLevel.BASIC],
            },
            {
                key: "cage",
                namePath: i18next.t("cage"),
                packageKeys: [LicPackageKeys.CAGE],
                packageLevels: [LicPackageLevel.BASIC],
            },
            {
                key: "weights",
                namePath: i18next.t("weights"),
                packageKeys: [LicPackageKeys.SMALL_CAGE],
                packageLevels: [LicPackageLevel.BASIC],
            },
            {
                key: "silo",
                namePath: i18next.t("siloses"),
                packageKeys: [LicPackageKeys.SILO],
                packageLevels: [LicPackageLevel.BASIC],
            },
            {
                key: "water",
                namePath: i18next.t("water"),
                packageKeys: [LicPackageKeys.COUNTER],
                packageLevels: [LicPackageLevel.BASIC],
            },
            {
                key: "electricity",
                namePath: i18next.t("electricity"),
                packageKeys: [LicPackageKeys.COUNTER],
                packageLevels: [LicPackageLevel.BASIC],
            },
            {
                key: "chainFeeding",
                namePath: i18next.t("chainFeeding"),
                packageKeys: [LicPackageKeys.CHAIN],
                packageLevels: [LicPackageLevel.BASIC],
            },
            {
                key: "map",
                namePath: i18next.t("map"),
                packageKeys: [LicPackageKeys.ADMINISTRATION],
                packageLevels: [LicPackageLevel.BASIC],
            },
            {
                key: "results",
                namePath: i18next.t("results.results"),
                packageKeys: [LicPackageKeys.SOW, LicPackageKeys.PORKER],
                packageLevels: [LicPackageLevel.BASIC, LicPackageLevel.BASIC],
            }
        ];
        const farm = props.farms.find(item => item.FarmID === props.match.params.FarmID);
        const devices = props.devices;
        const tabs = TabNames.filter((tab) => {
            const {key} = tab;
            if (key === "results") return !isMobile() && hasMinPackageLevel(props.licPackages, tab.packageKeys, tab.packageLevels);
            return hasMinPackageLevel(props.licPackages, tab.packageKeys, tab.packageLevels);
        });
        const viewType = props.match.params.viewType;
        let index = tabs.findIndex(t => t.key === viewType);
        if (index === -1) {
            index = 0;
        }
        const newViewType = get(tabs[index], "key", "");
        return {
            index: index,
            redirect: viewType !== newViewType,
            viewType: newViewType,
            tabs,
            licPackages: props.licPackages,
            farm,
            devices,
            FarmID: props.match.params.FarmID
        };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (!isEqual(nextProps.lang , prevState.lang) || nextProps.match.params.viewType !== prevState.viewType || nextProps.match.params.FarmID !== prevState.FarmID || !isEqual(nextProps.devices, prevState.devices) || !isEqual(nextProps.licPackages, prevState.licPackages)) {
            return {
                ...FarmView.getData(nextProps)
            }
        }
        return null;
    }

    componentDidMount() {
        const {redirect, viewType} = this.state;
        if (redirect) {
            const {farm, history} = this.props;
            history.replace(`/${farm}/farm/${viewType}`)
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {redirect, viewType} = this.state;
        if (prevState.redirect !== redirect) {
            if (redirect) {
                const {farm, history} = this.props;
                history.replace(`/${farm}/farm/${viewType}`)
            }
        }

    }

    onTabChange = index => {
        // sinon.restore();
        const {tabs} = this.state;
        const {farm, history} = this.props;
        history.push(`/${farm}/farm/${get(tabs[index], "key")}`)
    };

    componentWillUnmount() {
        sinon.restore();
    }

    render() {
        const {farm, devices, tabs, viewType, index} = this.state;
        const {t} = this.props;
        const tabNames = tabs.map(o => ({name: t(o.namePath)}));
        console.log(tabNames)
        // const {blockSwipeFeeding} = this.props;
        if (!farm) return <NotFound/>;
        const scales = [];
        const cages = [];
        const waterFlowMeters = [];
        const electricityFlowMeters = [];
        const chainFeeding = [];
        const pigletScales = [];
        devices.forEach(device => {
            switch (device.DevType) {
                case DevType.SCALE:
                    scales.push(device);
                    break;
                case DevType.CAGE:
                    cages.push(device);
                    break;
                case DevType.WATER_FLOW_METER:
                    waterFlowMeters.push(device);
                    break;
                case DevType.ELECTRICITY_FLOW_METER:
                    electricityFlowMeters.push(device);
                    break;
                case DevType.CHAIN_FEEDING:
                    chainFeeding.push(device);
                    break;
                case DevType.SMALL_CAGE:
                    pigletScales.push(device);
                    break;
                default:
                    break;

            }
        });
        const siloses = getFlatDevices(scales);
        return (
            <div>
                <Header text={farm.FarmName} tabs={tabNames} onTabChange={this.onTabChange} active={index}/>
                <ViewContainer addPaddingForButtons={!["general", "map"].includes(viewType)}>
                    {
                        (viewType === "general") &&
                        <GeneralTab key={"general"}/>
                    }
                    {
                        (viewType === "inventory") &&
                        <Inventory key={"inventory"} loc={farm} level={Level.FARM}/>
                    }
                    {
                        (viewType === "feeding") &&
                        <DispenserTab key={"feeding"} dataKey={[]}/>
                    }
                    {
                        (viewType === "climate") &&
                        <ClimateWSTTab key={"climate"}/>
                    }
                    {
                        (viewType === "cage") &&
                        <SeparationCageTab key={"cage"} cages={cages}/>
                    }
                    {
                        (viewType === "weights") &&
                        <PigletScaleTab key={"weights"} scales={pigletScales}/>
                    }
                    {
                        (viewType === "silo") &&
                        <SiloTab key={"silo"} siloses={siloses}/>
                    }
                    {
                        (viewType === "water") &&
                        <WaterTab key={"water"} waterFlowMeters={waterFlowMeters}/>
                    }
                    {
                        (viewType === "electricity") &&
                        <ElectricityTab key={"electricity"} electricityFlowMeters={electricityFlowMeters}/>
                    }
                    {
                        (viewType === "chainFeeding") &&
                        <ChainFeedingTab key={"chainFeeding"} chainFeeding={chainFeeding}/>
                    }
                    {
                        (viewType === "map") &&
                        <MapTab key={"map"}/>
                    }
                    {
                        (viewType === "results") &&
                        <Results key={"results"}/>
                    }
                </ViewContainer>
            </div>
        );
    }
}

export default withTranslation()(FarmView);
